@charset "UTF-8";
.grid {
  width: 100%;
  display: block;
  padding: 0; }

.grid.wide {
  max-width: 1000px;
  margin: 0 auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px; }

.row.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.col {
  padding-left: 4px;
  padding-right: 4px; }

.row.no-gutters .col {
  padding-left: 0;
  padding-right: 0; }

.c-0 {
  display: none; }

.c-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.c-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.c-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.c-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.c-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.c-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.c-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.c-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.c-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.c-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.c-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.c-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.c-o-1 {
  margin-left: 8.33333%; }

.c-o-2 {
  margin-left: 16.66667%; }

.c-o-3 {
  margin-left: 25%; }

.c-o-4 {
  margin-left: 33.33333%; }

.c-o-5 {
  margin-left: 41.66667%; }

.c-o-6 {
  margin-left: 50%; }

.c-o-7 {
  margin-left: 58.33333%; }

.c-o-8 {
  margin-left: 66.66667%; }

.c-o-9 {
  margin-left: 75%; }

.c-o-10 {
  margin-left: 83.33333%; }

.c-o-11 {
  margin-left: 91.66667%; }

/* >= Tablet */
@media (min-width: 1000px) {
  .row {
    margin-left: -8px;
    margin-right: -8px; }
  .col {
    padding-left: 8px;
    padding-right: 8px; }
  .m-0 {
    display: none; }
  .m-1,
  .m-2,
  .m-3,
  .m-4,
  .m-5,
  .m-6,
  .m-7,
  .m-8,
  .m-9,
  .m-10,
  .m-11,
  .m-12 {
    display: block; }
  .m-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .m-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .m-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .m-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .m-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .m-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .m-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .m-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .m-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .m-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .m-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .m-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .m-o-1 {
    margin-left: 8.33333%; }
  .m-o-2 {
    margin-left: 16.66667%; }
  .m-o-3 {
    margin-left: 25%; }
  .m-o-4 {
    margin-left: 33.33333%; }
  .m-o-5 {
    margin-left: 41.66667%; }
  .m-o-6 {
    margin-left: 50%; }
  .m-o-7 {
    margin-left: 58.33333%; }
  .m-o-8 {
    margin-left: 66.66667%; }
  .m-o-9 {
    margin-left: 75%; }
  .m-o-10 {
    margin-left: 83.33333%; }
  .m-o-11 {
    margin-left: 91.66667%; } }

/* PC medium resolution > */
@media (min-width: 1113px) {
  .row {
    margin-left: -12px;
    margin-right: -12px; }
  .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .col {
    padding-left: 12px;
    padding-right: 12px; }
  .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .l-0 {
    display: none; }
  .l-1,
  .l-2,
  .l-2-4,
  .l-3,
  .l-4,
  .l-5,
  .l-6,
  .l-7,
  .l-8,
  .l-9,
  .l-10,
  .l-11,
  .l-12 {
    display: block; }
  .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .l-o-1 {
    margin-left: 8.33333%; }
  .l-o-2 {
    margin-left: 16.66667%; }
  .l-o-3 {
    margin-left: 25%; }
  .l-o-4 {
    margin-left: 33.33333%; }
  .l-o-5 {
    margin-left: 41.66667%; }
  .l-o-6 {
    margin-left: 50%; }
  .l-o-7 {
    margin-left: 58.33333%; }
  .l-o-8 {
    margin-left: 66.66667%; }
  .l-o-9 {
    margin-left: 75%; }
  .l-o-10 {
    margin-left: 83.33333%; }
  .l-o-11 {
    margin-left: 91.66667%; } }

/* Tablet - PC low resolution */
@media (min-width: 740px) and (max-width: 1023px) {
  .wide {
    width: 644px; } }

/* > PC low resolution */
@media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px; }
  .wide .row {
    margin-left: -12px;
    margin-right: -12px; }
  .wide .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .wide .col {
    padding-left: 12px;
    padding-right: 12px; }
  .wide .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .wide .l-0 {
    display: none; }
  .wide .l-1,
  .wide .l-2,
  .wide .l-2-4,
  .wide .l-3,
  .wide .l-4,
  .wide .l-5,
  .wide .l-6,
  .wide .l-7,
  .wide .l-8,
  .wide .l-9,
  .wide .l-10,
  .wide .l-11,
  .wide .l-12 {
    display: block; }
  .wide .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .wide .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .wide .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .wide .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .wide .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .wide .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .wide .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .wide .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .wide .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .wide .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .wide .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .wide .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .wide .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .wide .l-o-1 {
    margin-left: 8.33333%; }
  .wide .l-o-2 {
    margin-left: 16.66667%; }
  .wide .l-o-3 {
    margin-left: 25%; }
  .wide .l-o-4 {
    margin-left: 33.33333%; }
  .wide .l-o-5 {
    margin-left: 41.66667%; }
  .wide .l-o-6 {
    margin-left: 50%; }
  .wide .l-o-7 {
    margin-left: 58.33333%; }
  .wide .l-o-8 {
    margin-left: 66.66667%; }
  .wide .l-o-9 {
    margin-left: 75%; }
  .wide .l-o-10 {
    margin-left: 83.33333%; }
  .wide .l-o-11 {
    margin-left: 91.66667%; } }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

@font-face {
  font-family: "Myfont";
  src: url("../assets/fonts/Baloo-Regular.ttf"); }

/* >= Tablet min-width: 740px */
/* PC medium resolution > min-width: 1113px */
/* Tablet - PC low resolution (min-width: 740px) and (max-width: 1023px)*/
/* > PC low resolution (min-width: 1024px) and (max-width: 1239px)*/
@keyframes fireFadeIn {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

*,
*::after,
*::before {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #222222; }

.top-container {
  width: 90%;
  margin: 0 auto; }
  @media (max-width: 1239.98px) {
    .top-container {
      width: 90%; } }

.container {
  width: 980px;
  margin: 0 auto; }
  @media (max-width: 999.98px) {
    .container {
      width: 90%; } }

.right-container {
  padding-left: calc((100vw - 980px) / 2); }
  @media (max-width: 999.98px) {
    .right-container {
      padding-left: 5%; } }

.left-container {
  padding-right: calc((100vw - 980px) / 2); }
  @media (max-width: 999.98px) {
    .left-container {
      padding-right: 5%; } }

.flex-box {
  display: flex; }

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.btn {
  width: 212px;
  height: 53px;
  background-color: #ED3737;
  border-radius: 25px;
  box-shadow: 0 3px 6px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  border: 0px;
  letter-spacing: 1px;
  margin-top: 140px; }

.require {
  width: 36px;
  height: 18px;
  border-radius: 21px;
  background-color: #727272;
  color: white;
  font-size: 9px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px; }

.blue-oval {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
  padding: 7px 11px;
  background-color: #73CBE6;
  color: #ffffff;
  margin: 5px 0 0 6px; }
  @media (max-width: 699.98px) {
    .blue-oval {
      font-size: 11px;
      margin-top: -4px; } }

.blue-oval2 {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
  padding: 7px 16px;
  background-color: #73CBE6;
  color: #ffffff;
  white-space: nowrap;
  margin: 5px 0 0 6px; }
  @media (max-width: 699.98px) {
    .blue-oval2 {
      font-size: 11px;
      padding: 7px 14.5px;
      margin-top: 0px; } }

.sp-no-br {
  display: block; }
  @media (max-width: 699.98px) {
    .sp-no-br {
      display: none; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 1; }
  60% {
    transform: scale(1.2);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes slideUp {
  0% {
    transform: translate(-50%, 100px);
    opacity: 0; }
  100% {
    transform: translateY(-50%, 0);
    opacity: 1; } }

@keyframes slideUpMin {
  0% {
    transform: translate(-50%, 40px);
    opacity: 0; }
  100% {
    transform: translateY(-50%, 0);
    opacity: 1; } }

header {
  width: calc(100% - 352px);
  height: auto;
  padding-right: 40px;
  padding-left: 40px;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0px;
  z-index: 9999; }
  @media (max-width: 999.98px) {
    header {
      width: 100%; } }
  @media (max-width: 699.98px) {
    header {
      background-color: transparent; } }
  header .header-menu {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 699.98px) {
      header .header-menu {
        display: none; } }
    header .header-menu__left--font {
      color: #080808;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
      font-weight: bold;
      font-size: 16px; }
    header .header-menu__right {
      width: 550px;
      display: flex;
      justify-content: space-between; }
      header .header-menu__right--font {
        color: #080808;
        font-size: 14px;
        font-weight: medium; }
  header .active {
    position: relative; }
    header .active:after {
      content: url("../assets/header/header04.png");
      position: absolute;
      right: -30px;
      top: -40px;
      z-index: -1; }
  header .active2 {
    position: relative; }
    header .active2:after {
      content: url("../assets/header/header04.png");
      position: absolute;
      right: -36px;
      top: -40px;
      z-index: -1; }
  header .active3 {
    position: relative; }
    header .active3:after {
      content: url("../assets/header/header04.png");
      position: absolute;
      transform: scale(1.2);
      right: -18px;
      top: -40px;
      z-index: -1; }
  header .active4 {
    position: relative; }
    header .active4:after {
      content: url("../assets/header/header04.png");
      position: absolute;
      transform: scale(1.3);
      right: -10px;
      top: -40px;
      z-index: -1; }
  header .active5 {
    position: relative; }
    header .active5:after {
      content: url("../assets/header/header04.png");
      position: absolute;
      transform: scale(1.3);
      width: 170px;
      right: -85px;
      top: -40px;
      z-index: -1; }

.sp-header {
  display: none; }
  @media (max-width: 699.98px) {
    .sp-header {
      display: block;
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 9px;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
      font-weight: bold; } }

.footer {
  position: relative;
  height: 295px;
  overflow: hidden; }
  @media (max-width: 699.98px) {
    .footer {
      height: 240px; } }
  .footer__background {
    position: relative;
    top: -105px;
    left: calc(50% - 700px); }
    @media (max-width: 699.98px) {
      .footer__background {
        display: none; } }
    .footer__background--sp {
      display: none; }
      @media (max-width: 699.98px) {
        .footer__background--sp {
          display: block;
          top: -20px;
          position: relative;
          left: calc(50% - 340px); } }
  .footer__top {
    position: absolute;
    top: 90px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 699.98px) {
      .footer__top {
        top: 80px; } }
    .footer__top--img {
      display: block; }
      @media (max-width: 699.98px) {
        .footer__top--img {
          display: none; } }
    .footer__top--img-sp {
      display: none; }
      @media (max-width: 699.98px) {
        .footer__top--img-sp {
          display: block; } }
  .footer__bottom {
    position: absolute;
    top: 195px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 699.98px) {
      .footer__bottom {
        top: 155px; } }
    .footer__bottom--font1, .footer__bottom--font2 {
      font-size: 16px;
      font-weight: 500px;
      text-align: center;
      white-space: nowrap; }
      @media (max-width: 699.98px) {
        .footer__bottom--font1, .footer__bottom--font2 {
          font-size: 12px;
          line-height: 18px; } }
    .footer__bottom--font2 {
      margin-top: 12px; }
      @media (max-width: 699.98px) {
        .footer__bottom--font2 {
          margin-top: 5px; } }
    .footer__bottom--font3 {
      text-align: center;
      margin-top: 20px;
      font-size: 10px; }
      @media (max-width: 699.98px) {
        .footer__bottom--font3 {
          font-size: 5px;
          margin-top: 12px; } }

.sp-br {
  display: none; }
  @media (max-width: 699.98px) {
    .sp-br {
      display: block; } }

.ssp-br {
  display: none; }
  @media (max-width: 399.98px) {
    .ssp-br {
      display: none; } }

.top-view-second {
  top: 1000px; }

.form {
  height: 100vh;
  width: 352px;
  position: fixed;
  top: 0;
  right: 0;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  background-color: #ffffff;
  z-index: 99; }
  @media (max-width: 999.98px) {
    .form {
      top: auto;
      bottom: -450px;
      display: block;
      width: 100%;
      height: 450px;
      margin-top: 70px;
      transition: 0.5s; } }
  @media (max-width: 699.98px) {
    .form {
      height: 650px;
      bottom: -650px; } }
  .form__second {
    position: absolute;
    top: 1000px;
    right: 0; }
  .form__answer {
    display: flex; }
    .form__answer label {
      display: flex;
      font-size: 13px; }
  .form__title {
    width: 352px;
    height: 90px;
    background-color: #ED3737;
    color: white;
    border-radius: 15px 0 0 0;
    font-size: 18px; }
    @media (max-width: 999.98px) {
      .form__title {
        height: 70px;
        display: block;
        width: 100%;
        position: relative;
        background-color: transparent;
        top: -70px;
        height: 0px; } }
    .form__title--bg {
      display: none; }
      @media (max-width: 999.98px) {
        .form__title--bg {
          display: block;
          position: absolute;
          z-index: -1;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 1210px; } }
    .form__title--font {
      font-size: 18px;
      line-height: 27px;
      font-weight: bold;
      text-align: center;
      position: relative;
      top: 22px; }
      @media (max-width: 999.98px) {
        .form__title--font {
          font-size: 17px;
          top: 7px;
          position: relative;
          display: inline-block;
          left: 50%;
          transform: translateX(-50%); } }
      .form__title--font:before {
        content: url("../assets/header/header01.png");
        position: absolute;
        left: 96px;
        top: 3px; }
        @media (max-width: 999.98px) {
          .form__title--font:before {
            left: -10px; } }
      .form__title--font:after {
        content: url("../assets/header/header02.png");
        position: absolute;
        right: 92px;
        top: 3px; }
        @media (max-width: 999.98px) {
          .form__title--font:after {
            right: -10px; } }
    .form__title--text {
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      text-align: center;
      position: relative;
      top: 25px; }
      @media (max-width: 999.98px) {
        .form__title--text {
          font-size: 20px;
          top: 14px;
          letter-spacing: 0.8px; } }
    .form__title--lg-img {
      display: none; }
      @media (max-width: 999.98px) {
        .form__title--lg-img {
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          overflow-x: hidden;
          z-index: -1; } }
      @media (max-width: 699.98px) {
        .form__title--lg-img {
          display: none; } }
    .form__title--sp-img {
      display: none; }
      @media (max-width: 699.98px) {
        .form__title--sp-img {
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          overflow-x: hidden;
          z-index: -1; } }
  .form__main {
    padding: 30px 40px 0; }
    @media (max-width: 699.98px) {
      .form__main {
        padding: 30px 0 0 0; } }
    .form__main--font {
      font-size: 20px;
      text-align: center;
      margin-top: 105px; }
    .form__main--text1 {
      font-size: 16px;
      text-align: center;
      margin-top: 50px;
      font-weight: 500; }
    .form__main--text2 {
      font-size: 16px;
      text-align: center;
      margin-top: 15px;
      line-height: 30px;
      font-weight: 500; }
  .form .progressbar {
    position: relative;
    padding: 0 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 999.98px) {
      .form .progressbar {
        padding: 0;
        width: 265px;
        margin: 0 auto; } }
    .form .progressbar li {
      position: relative;
      list-style-type: none;
      text-align: center;
      width: 33.333%;
      color: #080808;
      font-weight: bold;
      font-size: 17px; }
      .form .progressbar li:before {
        display: block;
        width: 15px;
        height: 15px;
        margin: 7px auto 19px auto;
        content: "";
        text-align: center;
        border-radius: 50%;
        background: linear-gradient(to right, #ED3737 50%, #EBDFDF 50%);
        background-size: 200% 100%;
        background-position: right bottom; }
      .form .progressbar li:after {
        position: absolute;
        z-index: -1;
        top: 14px;
        left: -50%;
        width: 100%;
        height: 2px;
        content: "";
        background-color: #EBDFDF; }
      .form .progressbar li:first-child:after {
        content: none; }
    .form .progressbar .active,
    .form .progressbar .complete {
      color: #080808; }
      .form .progressbar .active:before,
      .form .progressbar .complete:before {
        width: 20px;
        height: 20px;
        margin: 4.5px auto 17px;
        background-position: left bottom;
        transition: 0.8s;
        transition-delay: 0.99s; }
      .form .progressbar .active:after,
      .form .progressbar .complete:after {
        background-color: #EBDFDF; }
  .form .animation-border {
    position: absolute;
    display: block;
    height: 2px;
    left: 35px;
    top: 14px;
    z-index: 1;
    animation: border-back 1s ease 0s forwards;
    background-color: #EBDFDF; }

@keyframes border-back {
  0% {
    width: 0%;
    background-color: #ED3737; }
  100% {
    width: 92px;
    background-color: #ED3737; } }
  @media (max-width: 699.98px) {
    .form .main-form {
      display: block;
      margin: 0 auto;
      width: 320px; } }
  .form .attention {
    margin: 30px 0 -8px;
    font-size: 14px;
    font-weight: 500; }
    @media (max-width: 999.98px) {
      .form .attention {
        margin: 30px 0 15px;
        text-align: center; } }
    @media (max-width: 699.98px) {
      .form .attention {
        text-align: left;
        margin-left: 18px; } }
  @media (max-width: 999.98px) {
    .form .form-require-wrap-top,
    .form .form-require-wrap-bottom {
      display: flex;
      justify-content: center; } }
  @media (max-width: 699.98px) {
    .form .form-require-wrap-top,
    .form .form-require-wrap-bottom {
      display: block; } }
  .form .form-require-wrap-bottom {
    margin-top: 30px; }
    @media (max-width: 699.98px) {
      .form .form-require-wrap-bottom {
        margin-top: 0px; } }
  @media (max-width: 999.98px) {
    .form .form-require-wrap-bottom-second {
      display: flex;
      justify-content: center;
      margin-top: 0px; } }
  @media (max-width: 699.98px) {
    .form .form-require-wrap-bottom-second {
      display: block; } }
  .form .form-require {
    margin: 23px 0 0; }
    @media (max-width: 999.98px) {
      .form .form-require {
        margin: 0 20px; } }
    @media (max-width: 699.98px) {
      .form .form-require {
        margin: 0 20px 22px; } }
    .form .form-require__title {
      display: flex; }
      .form .form-require__title--font {
        font-size: 14px;
        font-weight: bold; }
    .form .form-require__main {
      margin-top: 6px;
      width: 272px;
      height: 32px;
      border: 1px solid #9D9D9D;
      font-size: 13px;
      padding: 6px 12px; }
      .form .form-require__main--answer {
        font-size: 13px; }
      .form .form-require__main--radio {
        width: 15px;
        height: 15px;
        margin: 0; }
    .form .form-require__select {
      appearance: none;
      margin-top: 8px;
      width: 252px;
      height: 32px;
      border: 1px solid #9D9D9D;
      font-size: 13px;
      padding: 6px 12px;
      background-image: url("../assets/contact/contact02.png");
      background-repeat: no-repeat;
      background-position: right 15px center; }
    .form .form-require__textarea {
      width: 272px;
      height: 100px;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
      font-size: 13px; }
    .form .form-require__text {
      font-size: 12px;
      color: #222222;
      margin: 2px 0 4px;
      font-weight: medium; }
    .form .form-require__wrap {
      display: flex;
      margin-top: 15px; }
    .form .form-require__answer {
      margin-right: 50px; }
    .form .form-require__answer2 {
      margin-right: 40px; }
  .form .select-arrow {
    position: relative; }
    .form .select-arrow:before {
      background-image: url("../assets/contact/contact02.png");
      position: absolute;
      top: 17px;
      right: 35px; }
  .form .form-require2 {
    margin-top: 30px; }
    @media (max-width: 999.98px) {
      .form .form-require2 {
        margin-right: 40px;
        margin-left: 0 !important; } }
    @media (max-width: 699.98px) {
      .form .form-require2 {
        margin-right: 0px;
        margin-left: 18px !important;
        margin-top: 21px; } }
  .form .form-require3 {
    margin-top: 30px;
    white-space: nowrap; }
    @media (max-width: 999.98px) {
      .form .form-require3 {
        margin-left: 20px;
        margin-right: 0 !important; } }
    @media (max-width: 699.98px) {
      .form .form-require3 {
        margin-top: 24px; } }
  .form .form-check {
    display: flex;
    justify-content: center;
    margin: 25px 0 0 0; }
    .form .form-check__checkbox {
      border-radius: 0; }
    .form .form-check__text {
      font-family: "Noto Sans JP", sans-serif;
      font-size: 13px;
      font-weight: medium; }
    .form .form-check__img {
      width: 7px;
      height: 7px;
      margin: 1px 2px 1px; }
  .form .form-btn {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 18px auto 0; }
    @media (max-width: 999.98px) {
      .form .form-btn {
        margin-top: 80px; } }
  @media (max-width: 999.98px) {
    .form .form-btn1 {
      margin-top: 80px; } }
  .form .form-btn2 {
    margin-top: 20px; }
  .form .personal-check {
    display: flex;
    margin-top: 90px;
    font-weight: 500;
    font-family: "Noto Sans JP", sans-serif; }
    @media (max-width: 999.98px) {
      .form .personal-check {
        margin-top: 50px;
        justify-content: center; } }
    @media (max-width: 999.98px) {
      .form .personal-check {
        margin-top: 35px; } }

.no-active {
  display: none !important; }

.active {
  display: block !important; }

@media (max-width: 999.98px) {
  .lg-br {
    display: none; } }

@media (max-width: 699.98px) {
  .lg-br {
    display: block; } }

@media (max-width: 999.98px) {
  .first-form {
    margin-top: 0px !important; } }

@media (max-width: 699.98px) {
  .first-form {
    margin-top: 12px !important; } }

@media (max-width: 999.98px) {
  .second-form {
    margin-top: 0px !important; } }

@media (max-width: 699.98px) {
  .second-form {
    margin-top: 24px !important; } }

.error-info {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #b32929;
  margin-left: 14px; }
  @media (max-width: 999.98px) {
    .error-info {
      display: block;
      margin-top: 5px; } }

.personal-check__box {
  display: none; }

.personal-check__font {
  font-size: 13px; }

.personal-check input[type="checkbox"] + label {
  display: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding-left: 50px;
  padding-right: 10px; }

/* ラベルの左に表示させる正方形のボックス□ */
.personal-check input[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  margin-top: -6px;
  left: 26px;
  top: 50%;
  border: 1px solid #9D9D9D;
  background-color: #fff; }

/* チェックが入った時のレ点 */
.personal-check input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 14px;
  height: 7px;
  margin-top: -4px;
  top: 50%;
  left: 26px;
  transform: rotate(-45deg);
  border-bottom: 3px solid;
  border-left: 3px solid;
  border-color: #585753; }

*,
*::after,
*::before {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #222222; }

.top-container {
  width: 90%;
  margin: 0 auto; }
  @media (max-width: 1239.98px) {
    .top-container {
      width: 90%; } }

.container {
  width: 980px;
  margin: 0 auto; }
  @media (max-width: 999.98px) {
    .container {
      width: 90%; } }

.right-container {
  padding-left: calc((100vw - 980px) / 2); }
  @media (max-width: 999.98px) {
    .right-container {
      padding-left: 5%; } }

.left-container {
  padding-right: calc((100vw - 980px) / 2); }
  @media (max-width: 999.98px) {
    .left-container {
      padding-right: 5%; } }

.flex-box {
  display: flex; }

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.btn {
  width: 212px;
  height: 53px;
  background-color: #ED3737;
  border-radius: 25px;
  box-shadow: 0 3px 6px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  border: 0px;
  letter-spacing: 1px;
  margin-top: 140px; }

.require {
  width: 36px;
  height: 18px;
  border-radius: 21px;
  background-color: #727272;
  color: white;
  font-size: 9px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px; }

.blue-oval {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
  padding: 7px 11px;
  background-color: #73CBE6;
  color: #ffffff;
  margin: 5px 0 0 6px; }
  @media (max-width: 699.98px) {
    .blue-oval {
      font-size: 11px;
      margin-top: -4px; } }

.blue-oval2 {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
  padding: 7px 16px;
  background-color: #73CBE6;
  color: #ffffff;
  white-space: nowrap;
  margin: 5px 0 0 6px; }
  @media (max-width: 699.98px) {
    .blue-oval2 {
      font-size: 11px;
      padding: 7px 14.5px;
      margin-top: 0px; } }

.sp-no-br {
  display: block; }
  @media (max-width: 699.98px) {
    .sp-no-br {
      display: none; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 1; }
  60% {
    transform: scale(1.2);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes slideUp {
  0% {
    transform: translate(-50%, 100px);
    opacity: 0; }
  100% {
    transform: translateY(-50%, 0);
    opacity: 1; } }

@keyframes slideUpMin {
  0% {
    transform: translate(-50%, 40px);
    opacity: 0; }
  100% {
    transform: translateY(-50%, 0);
    opacity: 1; } }

.fv-main {
  width: 100%;
  height: 535px;
  margin-top: 80px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  overflow-x: hidden;
  overflow-y: hidden; }
  @media (max-width: 1309.98px) {
    .fv-main {
      height: 500px; } }
  @media (max-width: 1179.98px) {
    .fv-main {
      height: 385px; } }
  @media (max-width: 699.98px) {
    .fv-main {
      margin-top: unset;
      height: 500px; } }
  .fv-main__middle {
    width: 954px;
    margin: 0 auto;
    position: relative; }
    @media (max-width: 1309.98px) {
      .fv-main__middle {
        width: 858px; } }
    @media (max-width: 1179.98px) {
      .fv-main__middle {
        width: 650px; } }
    @media (max-width: 699.98px) {
      .fv-main__middle {
        width: 548px;
        margin-top: 40px; } }
    .fv-main__middle--img {
      width: 97%; }
      @media (max-width: 1309.98px) {
        .fv-main__middle--img {
          width: 858px; } }
      @media (max-width: 1179.98px) {
        .fv-main__middle--img {
          width: 650px; } }
    .fv-main__middle--font {
      position: absolute;
      font-size: 50px;
      top: 180px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      font-weight: bold;
      line-height: 83px;
      letter-spacing: 2px;
      opacity: 0;
      animation: slideUp 0.6s normal 1.6s forwards; }
      @media (max-width: 1309.98px) {
        .fv-main__middle--font {
          top: 160px;
          font-size: 44px; } }
      @media (max-width: 699.98px) {
        .fv-main__middle--font {
          font-size: 33px;
          line-height: 60px;
          top: 155px; } }
      @media (max-width: 1179.98px) {
        .fv-main__middle--font {
          font-size: 36px;
          line-height: 60px;
          top: 125px; } }
      @media (max-width: 699.98px) {
        .fv-main__middle--font {
          top: 160px;
          line-height: 50px; } }
      @media (max-width: 434.98px) {
        .fv-main__middle--font {
          top: 160px;
          font-size: 30px;
          letter-spacing: 1px; } }
    .fv-main__middle--emphasis {
      font-size: 60px; }
      @media (max-width: 1309.98px) {
        .fv-main__middle--emphasis {
          font-size: 56px; } }
      @media (max-width: 1179.98px) {
        .fv-main__middle--emphasis {
          font-size: 42px; } }
      @media (max-width: 699.98px) {
        .fv-main__middle--emphasis {
          font-size: 39px; } }
      @media (max-width: 434.98px) {
        .fv-main__middle--emphasis {
          font-size: 36px; } }
    .fv-main__middle--sp-group, .fv-main__middle--sp-top, .fv-main__middle--sp-bottom, .fv-main__middle--sp-img {
      display: none; }
    .fv-main__middle .sp-br {
      display: none; }
    @media (max-width: 699.98px) {
      .fv-main__middle--group-wrap, .fv-main__middle--top-wrap, .fv-main__middle--bottom-wrap, .fv-main__middle--img {
        display: none; }
      .fv-main__middle--sp-group, .fv-main__middle--sp-top, .fv-main__middle--sp-bottom, .fv-main__middle--sp-img {
        display: block; } }
  .fv-main__text {
    font-size: 45px;
    font-weight: bold;
    text-align: center;
    margin-top: 35px;
    letter-spacing: 1px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif; }
    @media (max-width: 1309.98px) {
      .fv-main__text {
        font-size: 47px; } }
    @media (max-width: 699.98px) {
      .fv-main__text {
        margin-top: 95px;
        font-size: 32px;
        line-height: 50px;
        margin-top: 15px; } }
    .fv-main__text--emphasis {
      font-size: 54px;
      letter-spacing: 4px;
      margin-left: 3px; }
      @media (max-width: 699.98px) {
        .fv-main__text--emphasis {
          font-size: 39px;
          letter-spacing: 1px; } }

.fv-img1 {
  position: absolute;
  top: 35px;
  left: -155px;
  animation: fadeIn 1s normal none; }
  @media (max-width: 1309.98px) {
    .fv-img1 {
      width: calc(1272px * 0.9); } }
  @media (max-width: 1179.98px) {
    .fv-img1 {
      width: calc(1272px * 0.7);
      left: -106px;
      top: 25px; } }

.fv-img2 {
  position: absolute;
  bottom: 90px;
  left: -225px;
  z-index: 2;
  animation: fadeIn 1s normal none; }
  @media (max-width: 1309.98px) {
    .fv-img2 {
      width: calc(317px * 0.9); } }
  @media (max-width: 1179.98px) {
    .fv-img2 {
      width: calc(317px * 0.7);
      bottom: 62px;
      left: -170px; } }

.fv-img3 {
  position: absolute;
  top: -5px;
  left: 325px;
  z-index: 5;
  animation: zoomIn 0.6s normal 1.2s forwards;
  opacity: 0; }
  .fv-img3 p {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    letter-spacing: -1.5px;
    top: -100px; }
  @media (max-width: 1309.98px) {
    .fv-img3 {
      left: 307px; }
      .fv-img3 img {
        width: calc(305px * 0.9); }
      .fv-img3 p {
        top: -90px; } }
  @media (max-width: 1179.98px) {
    .fv-img3 {
      left: 230px; }
      .fv-img3 img {
        width: calc(305px * 0.7); }
      .fv-img3 p {
        top: -73px;
        font-size: 14px;
        letter-spacing: -1.2px; } }

.fv-img4 {
  position: absolute;
  top: 15px;
  left: -150px;
  width: 510px;
  animation: zoomIn 0.6s normal 0.8s forwards;
  opacity: 0; }
  .fv-img4 p {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    letter-spacing: -1.5px;
    top: -95px; }
  @media (max-width: 1389.98px) {
    .fv-img4 p {
      right: -40px; } }
  @media (max-width: 1309.98px) {
    .fv-img4 {
      left: -125px;
      top: 7px; }
      .fv-img4 p {
        right: -15px;
        top: -85px; }
      .fv-img4 img {
        width: calc(510px * 0.9); } }
  @media (max-width: 1179.98px) {
    .fv-img4 {
      left: -105px;
      top: 4px; }
      .fv-img4 img {
        width: calc(510px * 0.7); }
      .fv-img4 p {
        font-size: 14px;
        letter-spacing: -1.2px;
        top: -72px;
        right: 70px; } }
  @media (max-width: 1059.98px) {
    .fv-img4 p {
      right: 40px; } }
  @media (max-width: 999.98px) {
    .fv-img4 p {
      right: 70px; } }
  @media (max-width: 729.98px) {
    .fv-img4 p {
      right: 45px; } }

.fv-img5 {
  position: absolute;
  top: 8px;
  right: 40px;
  z-index: 2;
  animation: zoomIn 0.6s normal 1.6s forwards;
  opacity: 0; }
  .fv-img5 p {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    letter-spacing: -1.5px;
    top: -143px;
    line-height: 24px; }
  @media (max-width: 1309.98px) {
    .fv-img5 {
      right: 15px;
      top: 2px; }
      .fv-img5 img {
        width: calc(335px * 0.9); }
      .fv-img5 p {
        top: -125px; } }
  @media (max-width: 1179.98px) {
    .fv-img5 {
      right: 3px; }
      .fv-img5 img {
        width: calc(335px * 0.7); }
      .fv-img5 p {
        top: -103px;
        font-size: 14px;
        letter-spacing: -1.2px;
        line-height: 20px; } }

.fv-img6 {
  position: absolute;
  bottom: 30px;
  right: -105px;
  z-index: 2;
  animation: zoomIn 0.6s normal 0.8s forwards;
  opacity: 0; }
  .fv-img6 p {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    letter-spacing: -1.5px;
    top: -95px;
    height: 0; }
  @media (max-width: 1389.98px) {
    .fv-img6 p {
      left: -15px; } }
  @media (max-width: 1309.98px) {
    .fv-img6 {
      right: -80px; }
      .fv-img6 img {
        width: calc(534px * 0.9); }
      .fv-img6 p {
        text-align: center;
        top: -85px;
        left: -10px; } }
  @media (max-width: 1239.98px) {
    .fv-img6 p {
      text-align: center;
      top: -85px;
      left: -40px; } }
  @media (max-width: 1179.98px) {
    .fv-img6 {
      right: -80px;
      bottom: 25px; }
      .fv-img6 img {
        width: calc(534px * 0.7); }
      .fv-img6 p {
        font-size: 14px;
        letter-spacing: -1.2px;
        top: -71px;
        left: 0px; } }
  @media (max-width: 1059.98px) {
    .fv-img6 p {
      left: -30px; } }
  @media (max-width: 999.98px) {
    .fv-img6 p {
      left: 0px; } }
  @media (max-width: 729.98px) {
    .fv-img6 p {
      left: -20px; } }

.fv-img7 {
  position: absolute;
  bottom: -2px;
  left: -35px;
  z-index: 2;
  animation: zoomIn 0.6s normal 1.2s forwards;
  opacity: 0; }
  .fv-img7 p {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    letter-spacing: -1.5px;
    top: -145px;
    line-height: 24px;
    height: 0; }
  @media (max-width: 1309.98px) {
    .fv-img7 {
      left: -18px; }
      .fv-img7 img {
        width: calc(285px * 0.9); }
      .fv-img7 p {
        top: -130px; } }
  @media (max-width: 1179.98px) {
    .fv-img7 {
      left: -18px;
      bottom: -8px; }
      .fv-img7 img {
        width: calc(285px * 0.7); }
      .fv-img7 p {
        top: -103px;
        font-size: 14px;
        letter-spacing: -1.2px;
        line-height: 20px; } }

.fv-img8 {
  position: absolute;
  bottom: 5px;
  left: 200px;
  width: 290px;
  z-index: 6;
  animation: zoomIn 0.6s normal 1.6s forwards;
  opacity: 0; }
  .fv-img8 p {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    letter-spacing: -1.5px;
    top: -100px;
    height: 0; }
  @media (max-width: 1309.98px) {
    .fv-img8 img {
      width: calc(290px * 0.9); }
    .fv-img8 p {
      top: -85px;
      left: -10px; } }
  @media (max-width: 1179.98px) {
    .fv-img8 {
      left: 155px;
      bottom: -4px; }
      .fv-img8 img {
        width: calc(290px * 0.7); }
      .fv-img8 p {
        font-size: 14px;
        letter-spacing: -1.2px;
        top: -67px;
        left: -40px; } }

.fv-img9 {
  position: absolute;
  bottom: -12px;
  left: -160px;
  z-index: 1;
  animation: fadeIn 1s normal none; }
  @media (max-width: 1309.98px) {
    .fv-img9 {
      left: -120px;
      width: calc(1144px * 0.95); } }
  @media (max-width: 1179.98px) {
    .fv-img9 {
      left: -120px;
      width: calc(1144px * 0.8); } }

.fv-img10 {
  position: absolute;
  right: -250px;
  top: 176px;
  z-index: 1;
  animation: fadeIn 1s normal none; }
  @media (max-width: 1309.98px) {
    .fv-img10 {
      right: -215px; }
      .fv-img10 img {
        width: calc(317px * 0.9); } }
  @media (max-width: 1179.98px) {
    .fv-img10 {
      right: -215px;
      top: 125px; }
      .fv-img10 img {
        width: calc(317px * 0.7); } }

.fv-sp-img1 {
  position: absolute;
  top: -72px;
  right: -115px;
  animation: fadeIn 1s normal none; }

.fv-sp-img2 {
  position: absolute;
  top: -18px;
  left: 60px;
  opacity: 0;
  animation: zoomIn 0.6s normal 0.8s forwards; }
  .fv-sp-img2 p {
    top: -90px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    line-height: 16px; }

.fv-sp-img3 {
  position: absolute;
  top: -45px;
  right: -2px;
  animation: zoomIn 0.6s normal 1.2s forwards;
  opacity: 0;
  display: block; }
  .fv-sp-img3 p {
    top: -100px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    line-height: 16px; }

.fv-sp-img4 {
  position: absolute;
  top: 33px;
  right: 58px;
  animation: zoomIn 0.6s normal 1.6s forwards;
  opacity: 0; }
  .fv-sp-img4 p {
    top: -90px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    line-height: 16px; }

.fv-sp-img5 {
  position: absolute;
  top: 300px;
  right: 62.5px;
  opacity: 0;
  animation: zoomIn 0.6s normal 0.8s forwards; }
  .fv-sp-img5 p {
    top: -88px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    line-height: 16px; }

.fv-sp-img6 {
  position: absolute;
  top: 345px;
  right: 218px;
  animation: zoomIn 0.6s normal 1.2s forwards;
  opacity: 0; }
  .fv-sp-img6 p {
    top: -90px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    line-height: 16px; }

.fv-sp-img7 {
  position: absolute;
  top: 290px;
  left: 70px;
  animation: zoomIn 0.6s normal 1.6s forwards;
  opacity: 0; }
  .fv-sp-img7 p {
    top: -65px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    line-height: 16px; }

.fv-sp-img8 {
  position: absolute;
  top: -45px;
  right: -2px;
  animation: zoomIn 0.6s normal 1.2s forwards;
  opacity: 0;
  display: block; }
  .fv-sp-img8 p {
    top: -65px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    line-height: 16px; }
    @media (max-width: 509.98px) {
      .fv-sp-img8 p {
        left: -40px; } }

.sp-br {
  display: none; }

@media (max-width: 699.98px) {
  .sp-br {
    display: block; } }

.section1 {
  width: 100%;
  overflow-x: hidden; }
  @media (max-width: 999.98px) {
    .section1 {
      width: 100%; } }
  @media (max-width: 699.98px) {
    .section1 {
      height: 1790px; } }

@media (max-width: 1309.98px) {
  #top-change {
    top: -80px !important; } }

@media (max-width: 699.98px) {
  #top-change {
    top: 0px !important; } }

#top-change2 {
  top: -170px; }
  @media (max-width: 1309.98px) {
    #top-change2 {
      top: -150px; } }
  @media (max-width: 699.98px) {
    #top-change2 {
      top: 0px; } }

#top-change3 {
  top: -285px; }
  @media (max-width: 1479.98px) {
    #top-change3 {
      top: -260px; } }
  @media (max-width: 1309.98px) {
    #top-change3 {
      top: -225px; } }
  @media (max-width: 699.98px) {
    #top-change3 {
      top: 0px; } }

.section1-title {
  font-size: 48px;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  text-align: center;
  margin: 50px auto 10px;
  position: relative;
  display: table; }
  @media (max-width: 699.98px) {
    .section1-title {
      font-size: 32px;
      margin-bottom: 20px;
      margin-top: 30px; } }
  .section1-title:before {
    content: url("../assets/section1/section1-05.png");
    position: absolute;
    left: -60px;
    top: -5px; }
    @media (max-width: 699.98px) {
      .section1-title:before {
        content: url("../assets/section1/section1-09.png");
        position: absolute;
        left: -45px; } }
  .section1-title:after {
    content: url("../assets/section1/section1-06.png");
    position: absolute;
    right: -75px;
    top: -5px; }
    @media (max-width: 699.98px) {
      .section1-title:after {
        content: url("../assets/section1/section1-10.png");
        position: absolute;
        right: -48px; } }

.section1-main1 {
  position: relative;
  width: 95%;
  height: 530px;
  margin-top: 60px;
  clip-path: polygon(0 0, 88% 12%, 100% 83%, 0 100%);
  background-color: #FFBCBC; }
  @media (max-width: 1479.98px) {
    .section1-main1 {
      height: 620px;
      width: 974px; } }
  @media (max-width: 699.98px) {
    .section1-main1 {
      width: 604px;
      height: 468px;
      margin-bottom: -68px;
      left: calc(50% - 300px);
      margin-top: 40px; } }
  .section1-main1__background {
    position: absolute;
    right: calc(40% - 365px);
    width: 100%;
    overflow-y: hidden;
    top: calc(-5%); }
    @media (max-width: 1479.98px) {
      .section1-main1__background {
        right: calc(50% - 465px);
        width: auto;
        top: auto;
        height: auto;
        overflow-y: auto; } }
    @media (max-width: 699.98px) {
      .section1-main1__background {
        display: none; } }
    .section1-main1__background--sp {
      display: none; }
      @media (max-width: 699.98px) {
        .section1-main1__background--sp {
          display: block;
          position: absolute;
          position: absolute;
          left: 50%;
          transform: translateX(-50%); } }

.section1-main2 {
  position: relative;
  right: -5%;
  height: 542px;
  clip-path: polygon(0 17%, 99% 0, 100% 100%, 11% 89%);
  background-color: #BCEFFF;
  top: -100px; }
  @media (max-width: 1479.98px) {
    .section1-main2 {
      height: 620px;
      width: 974px;
      top: -90px;
      left: calc(50% - 465px); } }
  @media (max-width: 699.98px) {
    .section1-main2 {
      width: 604px;
      height: 468px;
      margin-bottom: -51px;
      left: calc(50% - 300px); } }
  .section1-main2__background {
    position: absolute;
    left: calc(40% - 365px);
    width: 100%;
    overflow-y: hidden;
    top: calc(-5%); }
    @media (max-width: 1479.98px) {
      .section1-main2__background {
        position: absolute;
        left: calc(50% - 465px);
        width: auto;
        top: auto;
        height: auto;
        overflow-y: auto; } }
    @media (max-width: 699.98px) {
      .section1-main2__background {
        display: none; } }
    .section1-main2__background--sp {
      display: none; }
      @media (max-width: 699.98px) {
        .section1-main2__background--sp {
          display: block;
          position: absolute;
          right: calc(50% - 275px); } }

.section1-first,
.section1-second,
.section1-third,
.section1-fourth {
  position: absolute;
  right: calc(50% - 130px);
  display: flex;
  top: 21%; }
  @media (max-width: 1479.98px) {
    .section1-first,
    .section1-second,
    .section1-third,
    .section1-fourth {
      top: 95px; } }
  @media (max-width: 1239.98px) {
    .section1-first,
    .section1-second,
    .section1-third,
    .section1-fourth {
      top: 110px; } }
  @media (max-width: 699.98px) {
    .section1-first,
    .section1-second,
    .section1-third,
    .section1-fourth {
      top: 80px;
      width: 318px;
      right: calc(50% - 159px); } }
  .section1-first__left,
  .section1-second__left,
  .section1-third__left,
  .section1-fourth__left {
    display: flex;
    flex-direction: column;
    text-align: center; }
    @media (max-width: 699.98px) {
      .section1-first__left,
      .section1-second__left,
      .section1-third__left,
      .section1-fourth__left {
        margin-right: -42px; } }
    .section1-first__left--point,
    .section1-second__left--point,
    .section1-third__left--point,
    .section1-fourth__left--point {
      font-family: "Roboto", sans-serif;
      color: #ffffff;
      font-size: 24px;
      font-weight: 400; }
      @media (max-width: 699.98px) {
        .section1-first__left--point,
        .section1-second__left--point,
        .section1-third__left--point,
        .section1-fourth__left--point {
          font-size: 13px; } }
    .section1-first__left--number,
    .section1-second__left--number,
    .section1-third__left--number,
    .section1-fourth__left--number {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      color: #ffffff;
      font-size: 70px;
      margin-top: 8px; }
      @media (max-width: 699.98px) {
        .section1-first__left--number,
        .section1-second__left--number,
        .section1-third__left--number,
        .section1-fourth__left--number {
          font-size: 40px; } }
  .section1-first__right,
  .section1-second__right,
  .section1-third__right,
  .section1-fourth__right {
    width: 465px;
    margin-top: -3px; }
    .section1-first__right--text1,
    .section1-second__right--text1,
    .section1-third__right--text1,
    .section1-fourth__right--text1 {
      font-size: 35px;
      font-weight: bold;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
      text-align: center;
      line-height: 40px;
      margin-left: 40px; }
      @media (max-width: 699.98px) {
        .section1-first__right--text1,
        .section1-second__right--text1,
        .section1-third__right--text1,
        .section1-fourth__right--text1 {
          font-size: 21px;
          line-height: 1em;
          margin-top: -2px;
          margin-left: 12px; } }
    .section1-first__right--text2,
    .section1-second__right--text2,
    .section1-third__right--text2,
    .section1-fourth__right--text2 {
      font-size: 45px;
      line-height: 70px;
      margin-left: 15px;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
      font-weight: bold;
      text-align: center;
      opacity: 0;
      transform: translate(0, 20px);
      transition: 0.5s; }
      @media (max-width: 699.98px) {
        .section1-first__right--text2,
        .section1-second__right--text2,
        .section1-third__right--text2,
        .section1-fourth__right--text2 {
          font-size: 27px;
          line-height: 1em;
          margin-top: 15px; } }
    .section1-first__right--text3,
    .section1-second__right--text3,
    .section1-third__right--text3,
    .section1-fourth__right--text3 {
      font-size: 18px;
      font-weight: 500;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
      line-height: 24px;
      margin-top: 10px;
      letter-spacing: -1px; }
      @media (max-width: 699.98px) {
        .section1-first__right--text3,
        .section1-second__right--text3,
        .section1-third__right--text3,
        .section1-fourth__right--text3 {
          display: flex;
          width: 310px;
          font-size: 15px;
          line-height: 21px;
          margin: 25px 0 0 -2px;
          letter-spacing: 0.4px; } }
  .section1-first__bottom,
  .section1-second__bottom,
  .section1-third__bottom,
  .section1-fourth__bottom {
    margin-top: 30px;
    margin-left: 60px; }
    @media (max-width: 699.98px) {
      .section1-first__bottom,
      .section1-second__bottom,
      .section1-third__bottom,
      .section1-fourth__bottom {
        margin-top: 7px; } }
    @media (max-width: 699.98px) {
      .section1-first__bottom--img1,
      .section1-second__bottom--img1,
      .section1-third__bottom--img1,
      .section1-fourth__bottom--img1 {
        display: none; } }
    .section1-first__bottom--img1-sp,
    .section1-second__bottom--img1-sp,
    .section1-third__bottom--img1-sp,
    .section1-fourth__bottom--img1-sp {
      display: none; }
      @media (max-width: 699.98px) {
        .section1-first__bottom--img1-sp,
        .section1-second__bottom--img1-sp,
        .section1-third__bottom--img1-sp,
        .section1-fourth__bottom--img1-sp {
          display: block; } }

.section1-second,
.section1-fourth {
  left: calc(50% - 145px); }
  @media (max-width: 1479.98px) {
    .section1-second,
    .section1-fourth {
      top: 120px; } }
  @media (max-width: 1239.98px) {
    .section1-second,
    .section1-fourth {
      top: 120px;
      left: calc(50% - 260px); } }
  @media (max-width: 699.98px) {
    .section1-second,
    .section1-fourth {
      top: 90px;
      left: calc(50% - 159px); } }
  @media (max-width: 699.98px) {
    .section1-second,
    .section1-fourth {
      top: 90px;
      left: calc(50% - 159px); } }

#top-change2 {
  margin-top: 15px; }
  @media (max-width: 699.98px) {
    #top-change2 {
      margin-top: 0px; } }

.section1-second__right--text1 {
  margin-left: 70px;
  white-space: nowrap; }

.section1-second__right--text2 {
  margin-left: 58px; }
  @media (max-width: 699.98px) {
    .section1-second__right--text2 {
      margin-top: 19px; } }

.section1-second__bottom {
  margin-top: 0;
  margin-left: 60px; }
  @media (max-width: 699.98px) {
    .section1-second__bottom {
      margin-top: 19px;
      margin-left: 50px; } }

.section1-third__right--text1 {
  margin-left: 20px;
  white-space: nowrap; }
  @media (max-width: 699.98px) {
    .section1-third__right--text1 {
      margin-left: 35px; } }

.section1-third__right--text2 {
  margin-left: 45px; }
  @media (max-width: 699.98px) {
    .section1-third__right--text2 {
      margin-left: 40px; } }

.section1-third__bottom {
  margin-top: 55px;
  margin-left: -15px; }
  @media (max-width: 699.98px) {
    .section1-third__bottom {
      margin-top: 20px;
      margin-left: 20px; } }

.section1-fourth__right--text1 {
  margin-left: 80px;
  white-space: nowrap; }
  @media (max-width: 1059.98px) {
    .section1-fourth__right--text1 {
      margin-left: 60px; } }
  @media (max-width: 699.98px) {
    .section1-fourth__right--text1 {
      margin-left: 65px; } }

.section1-fourth__right--text2 {
  margin-left: 40px; }
  @media (max-width: 699.98px) {
    .section1-fourth__right--text2 {
      margin-left: 70px; } }

.section1-fourth__bottom {
  margin-top: 30px;
  margin-left: 25px; }
  @media (max-width: 699.98px) {
    .section1-fourth__bottom {
      margin-top: 28px; } }

.is-active {
  transform: translate(0, 0);
  opacity: 1; }

@media (max-width: 699.98px) {
  .none-sp {
    display: none; } }

.section2 {
  width: 100%;
  height: auto;
  margin-top: -180px; }
  @media (max-width: 699.98px) {
    .section2 {
      margin-top: 30px; } }

.section2-title {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px; }
  @media (max-width: 699.98px) {
    .section2-title {
      font-size: 2.6rem; } }
  .section2-title__main {
    margin-bottom: 16px; }
    @media (max-width: 699.98px) {
      .section2-title__main {
        margin-bottom: 8px; } }
  .section2-title__sub {
    font-size: 3rem;
    color: #A7C7F2; }
    @media (max-width: 699.98px) {
      .section2-title__sub {
        font-size: 1.6rem; } }
    .section2-title__sub span:first-child {
      margin-right: 16px; }

.slider {
  width: 100%; }

.slide {
  position: relative; }
  .slide .slide-bg {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%); }

.slide-content {
  display: flex;
  max-width: 854px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 32px;
  width: 90%;
  min-height: 440px; }
  @media (max-width: 699.98px) {
    .slide-content {
      flex-direction: column;
      width: 80%; } }
  .slide-content .thumbnail {
    width: 32%;
    max-width: 266px;
    height: auto; }
    @media (max-width: 699.98px) {
      .slide-content .thumbnail {
        width: 100%;
        max-width: unset;
        display: flex; } }
    .slide-content .thumbnail .img-wrapper {
      margin-bottom: 16px;
      width: 100%;
      height: auto; }
      @media (max-width: 699.98px) {
        .slide-content .thumbnail .img-wrapper {
          width: 37%;
          margin-right: 16px; } }
      .slide-content .thumbnail .img-wrapper img {
        width: 100%; }
    .slide-content .thumbnail__text {
      font-size: 2rem;
      line-height: 1.5; }
      @media (max-width: 699.98px) {
        .slide-content .thumbnail__text {
          font-size: 1.4rem; } }
  .slide-content .slide-main {
    margin-left: auto;
    width: 65%;
    height: auto; }
    @media (max-width: 699.98px) {
      .slide-content .slide-main {
        width: 100%; } }
    .slide-content .slide-main .copy {
      font-size: 2.8rem;
      font-weight: 700;
      line-height: 1.4;
      margin-bottom: 32px;
      text-align: justify; }
      @media (max-width: 699.98px) {
        .slide-content .slide-main .copy {
          font-size: 1.6rem;
          margin-bottom: 16px; } }
    .slide-content .slide-main .detail {
      font-size: 1.6rem;
      line-height: 1.7;
      letter-spacing: 0.001em;
      text-align: justify; }
      @media (max-width: 699.98px) {
        .slide-content .slide-main .detail {
          font-size: 1.2rem; } }

.slider-controller {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: fit-content; }
  .slider-controller .arrow-prev,
  .slider-controller .arrow-next {
    width: 48px;
    height: auto; }
    @media (max-width: 699.98px) {
      .slider-controller .arrow-prev,
      .slider-controller .arrow-next {
        width: 36px; } }
    .slider-controller .arrow-prev img,
    .slider-controller .arrow-next img {
      width: 100%; }
  .slider-controller .arrow-prev img {
    transform: rotate(180deg); }

.slide-pager {
  display: flex;
  color: transparent;
  margin-right: 80px;
  margin-left: 80px; }
  @media (max-width: 699.98px) {
    .slide-pager {
      margin-right: 50px;
      margin-left: 50px; } }
  .slide-pager li {
    margin-right: 30px; }
    @media (max-width: 699.98px) {
      .slide-pager li {
        margin-right: 20px; } }
    .slide-pager li:last-child {
      margin-right: unset; }
  .slide-pager li button {
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 20px;
    text-indent: -9999px;
    -webkit-appearance: none;
    background-color: #E6E6E6;
    padding: 0; }
    @media (max-width: 699.98px) {
      .slide-pager li button {
        width: 15px;
        height: 15px; } }
  .slide-pager .slick-active button {
    transform: scale(1.4);
    background-color: #A7C7F2; }

.slick-slide {
  overflow: hidden; }

.section3 {
  position: relative;
  margin-top: 360px;
  padding-top: 55px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  white-space: nowrap;
  background-color: #f0fbff;
  margin-bottom: 300px; }
  @media (max-width: 699.98px) {
    .section3 {
      margin-top: 200px; } }

.section3-background {
  width: 100%;
  position: absolute;
  -ms-zoom-animation: -1; }

.section3-background2 {
  position: absolute;
  width: 100%;
  z-index: -1; }

.section3-price-title,
.section3-flow-title-wrap {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-top: 0px;
  padding-top: 20px; }
  @media (max-width: 699.98px) {
    .section3-price-title,
    .section3-flow-title-wrap {
      font-size: 26px; } }

.section3-price-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.section3-price-title2 {
  font-size: 40px;
  font-weight: bold;
  text-align: center; }
  @media (max-width: 699.98px) {
    .section3-price-title2 {
      font-size: 26px; } }

.section3-flow-title-wrap {
  margin-bottom: 20px; }

.section3-price-subtitle,
.section3-flow-subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  color: #73CBE6;
  text-align: center;
  margin-top: 20px; }
  @media (max-width: 699.98px) {
    .section3-price-subtitle,
    .section3-flow-subtitle {
      font-size: 17px;
      margin-top: 10px; } }
  .section3-price-subtitle__span,
  .section3-flow-subtitle__span {
    margin-right: 12px; }
  .section3-price-subtitle :nth-child(2),
  .section3-flow-subtitle :nth-child(2) {
    margin-left: 12px;
    margin-right: 0; }
    @media (max-width: 699.98px) {
      .section3-price-subtitle :nth-child(2),
      .section3-flow-subtitle :nth-child(2) {
        margin-left: 0; } }

.ad-wrap {
  display: flex;
  margin-top: 40px;
  position: relative;
  height: 510px;
  width: 875px;
  margin: 40px auto 0; }
  @media (max-width: 1279.98px) {
    .ad-wrap {
      width: auto; } }
  @media (max-width: 699.98px) {
    .ad-wrap {
      height: auto;
      margin-top: 30px; } }

.sns-ad-wrap,
.listing-ad-wrap {
  height: 510px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 50px 38px 60px 38px;
  position: absolute; }
  @media (max-width: 1279.98px) {
    .sns-ad-wrap,
    .listing-ad-wrap {
      height: auto; } }
  @media (max-width: 699.98px) {
    .sns-ad-wrap,
    .listing-ad-wrap {
      height: 295px;
      padding: 20px 25px 20px; } }
  .sns-ad-wrap__title,
  .listing-ad-wrap__title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px; }
    @media (max-width: 1279.98px) {
      .sns-ad-wrap__title,
      .listing-ad-wrap__title {
        font-size: 31px; } }
    @media (max-width: 699.98px) {
      .sns-ad-wrap__title,
      .listing-ad-wrap__title {
        font-size: 18px;
        margin-bottom: 15px; } }
    .sns-ad-wrap__title--border,
    .listing-ad-wrap__title--border {
      background-color: #2BAAD1;
      height: 3px;
      width: 100%; }
  .sns-ad-wrap__main,
  .listing-ad-wrap__main {
    margin-top: 50px; }
    @media (max-width: 1279.98px) {
      .sns-ad-wrap__main,
      .listing-ad-wrap__main {
        margin-top: 30px; } }
    @media (max-width: 699.98px) {
      .sns-ad-wrap__main,
      .listing-ad-wrap__main {
        margin-top: 5px; } }

.sns-ad-wrap {
  top: 0;
  right: calc(50% + 15px); }
  @media (max-width: 1279.98px) {
    .sns-ad-wrap {
      left: 25px;
      width: calc(100% - 50px); } }
  @media (max-width: 699.98px) {
    .sns-ad-wrap {
      max-width: 370px;
      width: 90%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%); } }

.listing-ad-wrap {
  top: 0;
  left: calc(50% + 15px); }
  @media (max-width: 1279.98px) {
    .listing-ad-wrap {
      top: 525px;
      left: 25px;
      width: calc(100% - 50px); } }
  @media (max-width: 699.98px) {
    .listing-ad-wrap {
      top: 310px;
      max-width: 370px;
      width: 90%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%); } }

.sns-ad,
.listing-ad {
  display: flex; }
  @media (max-width: 1279.98px) {
    .sns-ad,
    .listing-ad {
      width: 100%; } }
  @media (max-width: 1279.98px) {
    .sns-ad__wrap,
    .listing-ad__wrap {
      width: 100%;
      display: flex; } }
  @media (max-width: 1279.98px) {
    .sns-ad__wrap--sp,
    .listing-ad__wrap--sp {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 300px; } }
  @media (max-width: 1279.98px) and (max-width: 699.98px) {
    .sns-ad__wrap--sp,
    .listing-ad__wrap--sp {
      width: 50%; } }
  .sns-ad__main1, .sns-ad__main2, .sns-ad__main3, .sns-ad__main4,
  .listing-ad__main1,
  .listing-ad__main2,
  .listing-ad__main3,
  .listing-ad__main4 {
    display: flex; }
    @media (max-width: 1279.98px) {
      .sns-ad__main1, .sns-ad__main2, .sns-ad__main3, .sns-ad__main4,
      .listing-ad__main1,
      .listing-ad__main2,
      .listing-ad__main3,
      .listing-ad__main4 {
        flex-direction: column;
        width: 100%;
        padding: 20px;
        margin: 0 !important; } }
    @media (max-width: 699.98px) {
      .sns-ad__main1, .sns-ad__main2, .sns-ad__main3, .sns-ad__main4,
      .listing-ad__main1,
      .listing-ad__main2,
      .listing-ad__main3,
      .listing-ad__main4 {
        width: auto;
        padding: 15px; } }
    .sns-ad__main1--left, .sns-ad__main2--left, .sns-ad__main3--left, .sns-ad__main4--left,
    .listing-ad__main1--left,
    .listing-ad__main2--left,
    .listing-ad__main3--left,
    .listing-ad__main4--left {
      width: 112px;
      margin-right: 50px;
      text-align: center; }
      @media (max-width: 1279.98px) {
        .sns-ad__main1--left, .sns-ad__main2--left, .sns-ad__main3--left, .sns-ad__main4--left,
        .listing-ad__main1--left,
        .listing-ad__main2--left,
        .listing-ad__main3--left,
        .listing-ad__main4--left {
          width: auto;
          margin: 0 0 20px 0; } }
      @media (max-width: 699.98px) {
        .sns-ad__main1--left, .sns-ad__main2--left, .sns-ad__main3--left, .sns-ad__main4--left,
        .listing-ad__main1--left,
        .listing-ad__main2--left,
        .listing-ad__main3--left,
        .listing-ad__main4--left {
          width: auto;
          margin: 0 0 20px 0; } }
    @media (max-width: 1279.98px) {
      .sns-ad__main1--right, .sns-ad__main2--right, .sns-ad__main3--right, .sns-ad__main4--right,
      .listing-ad__main1--right,
      .listing-ad__main2--right,
      .listing-ad__main3--right,
      .listing-ad__main4--right {
        width: auto;
        margin: 0;
        text-align: center; } }
  .sns-ad__main2,
  .listing-ad__main2 {
    margin-top: 45px; }
    @media (max-width: 1279.98px) {
      .sns-ad__main2,
      .listing-ad__main2 {
        padding-top: 35px; } }
    @media (max-width: 699.98px) {
      .sns-ad__main2,
      .listing-ad__main2 {
        padding-top: 18px; } }
    .sns-ad__main2--left,
    .listing-ad__main2--left {
      margin-bottom: 15px; }
    .sns-ad__main2--right span,
    .listing-ad__main2--right span {
      margin-top: 4px; }
      @media (max-width: 1279.98px) {
        .sns-ad__main2--right span,
        .listing-ad__main2--right span {
          margin-top: 0px; } }
  .sns-ad__main3,
  .listing-ad__main3 {
    margin-top: 45px; }
  .sns-ad__main4,
  .listing-ad__main4 {
    margin-top: 45px; }
    @media (max-width: 1279.98px) {
      .sns-ad__main4,
      .listing-ad__main4 {
        padding-top: 35px; } }
    @media (max-width: 699.98px) {
      .sns-ad__main4,
      .listing-ad__main4 {
        padding-top: 15px; } }

.left1-font,
.left2-font,
.left3-font,
.left4-font {
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold; }
  @media (max-width: 1279.98px) {
    .left1-font,
    .left2-font,
    .left3-font,
    .left4-font {
      font-size: 28px; } }
  @media (max-width: 699.98px) {
    .left1-font,
    .left2-font,
    .left3-font,
    .left4-font {
      font-size: 16px; } }

.right2-font-wrap {
  align-items: flex-start; }

.right1-font,
.right2-font,
.right3-font,
.right4-font {
  font-size: 20px;
  font-weight: 500;
  margin-top: -8px; }
  @media (max-width: 1279.98px) {
    .right1-font,
    .right2-font,
    .right3-font,
    .right4-font {
      font-size: 26px; } }
  @media (max-width: 1279.98px) and (max-width: 699.98px) {
    .right1-font,
    .right2-font,
    .right3-font,
    .right4-font {
      font-size: 15px; } }
  .right1-font span,
  .right2-font span,
  .right3-font span,
  .right4-font span {
    font-size: 42px;
    font-weight: bold; }
    @media (max-width: 699.98px) {
      .right1-font span,
      .right2-font span,
      .right3-font span,
      .right4-font span {
        font-size: 31px;
        margin-right: 3px;
        position: relative;
        top: 2px; } }

.right2-font-small {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; }
  @media (max-width: 1279.98px) {
    .right2-font-small {
      text-align: left;
      font-size: 19px;
      line-height: 25px;
      display: inline-block;
      margin: 10px auto 0; } }
  @media (max-width: 699.98px) {
    .right2-font-small {
      font-size: 12px;
      line-height: 20px; } }
  @media (max-width: 399.98px) {
    .right2-font-small {
      font-size: 11px;
      line-height: 18px; } }

.right2-font-wrap,
.right3-font-wrap {
  display: flex; }
  @media (max-width: 1279.98px) {
    .right2-font-wrap,
    .right3-font-wrap {
      justify-content: center; } }

.section3-img1 {
  height: 110px;
  position: relative; }
  .section3-img1__content {
    position: absolute;
    top: 35px;
    left: calc(50% - 21px); }
    @media (max-width: 699.98px) {
      .section3-img1__content {
        display: none; } }
  @media (max-width: 1279.98px) {
    .section3-img1 {
      margin-top: 510px; } }
  .section3-img1__sp {
    display: none; }
    @media (max-width: 699.98px) {
      .section3-img1__sp {
        display: block;
        position: absolute;
        top: 780px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%); } }

.option-wrap {
  position: relative;
  height: 485px; }
  @media (max-width: 699.98px) {
    .option-wrap {
      height: 380px; } }

.option {
  width: 855px;
  padding: 40px 55px 60px;
  background-color: #ffffff;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 1279.98px) {
    .option {
      width: calc(100% - 50px);
      top: 0;
      padding: 50px 55px 60px; } }
  @media (max-width: 699.98px) {
    .option {
      top: 35px;
      max-width: 365px;
      width: 90%;
      padding: 15px 25px 15px; } }
  .option__title {
    font-size: 24px;
    font-weight: bold;
    text-align: center; }
    @media (max-width: 1279.98px) {
      .option__title {
        font-size: 31px; } }
    @media (max-width: 699.98px) {
      .option__title {
        font-size: 18px; } }
    .option__title--border {
      background-color: #73CBE6;
      height: 3px;
      width: 100%;
      margin-top: 28px; }
      @media (max-width: 699.98px) {
        .option__title--border {
          margin-top: 12px; } }
  .option__main {
    display: flex; }
    @media (max-width: 1279.98px) {
      .option__main {
        flex-direction: column; } }
    .option__main--sp1, .option__main--sp2 {
      display: flex; }
      @media (max-width: 1279.98px) {
        .option__main--sp1, .option__main--sp2 {
          display: flex;
          width: 100%; } }

.option1,
.option2,
.option3,
.option4 {
  text-align: center; }
  @media (max-width: 1279.98px) {
    .option1,
    .option2,
    .option3,
    .option4 {
      width: 50%; } }
  .option1__title-wrap--title,
  .option2__title-wrap--title,
  .option3__title-wrap--title,
  .option4__title-wrap--title {
    font-size: 16px;
    font-weight: bold; }
    @media (max-width: 1279.98px) {
      .option1__title-wrap--title,
      .option2__title-wrap--title,
      .option3__title-wrap--title,
      .option4__title-wrap--title {
        font-size: 28px; } }
    @media (max-width: 699.98px) {
      .option1__title-wrap--title,
      .option2__title-wrap--title,
      .option3__title-wrap--title,
      .option4__title-wrap--title {
        font-size: 16px; } }
  .option1__title-wrap--detail,
  .option2__title-wrap--detail,
  .option3__title-wrap--detail,
  .option4__title-wrap--detail {
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    margin-top: 5px; }
    @media (max-width: 1279.98px) {
      .option1__title-wrap--detail,
      .option2__title-wrap--detail,
      .option3__title-wrap--detail,
      .option4__title-wrap--detail {
        font-size: 18px;
        font-weight: 500;
        position: relative;
        line-height: 27px;
        top: 15px; } }
    @media (max-width: 699.98px) {
      .option1__title-wrap--detail,
      .option2__title-wrap--detail,
      .option3__title-wrap--detail,
      .option4__title-wrap--detail {
        font-size: 11px;
        line-height: 14px;
        top: 6px; } }
    @media (max-width: 399.98px) {
      .option1__title-wrap--detail,
      .option2__title-wrap--detail,
      .option3__title-wrap--detail,
      .option4__title-wrap--detail {
        font-size: 10px;
        line-height: 12px;
        top: 6px; } }
  @media (max-width: 1279.98px) {
    .option1__main-top,
    .option2__main-top,
    .option3__main-top,
    .option4__main-top {
      display: flex;
      margin-top: 35px;
      justify-content: center; } }
  @media (max-width: 1279.98px) {
    .option1__main-top,
    .option2__main-top,
    .option3__main-top,
    .option4__main-top {
      margin-top: 28px; } }
  .option1__main-top--font,
  .option2__main-top--font,
  .option3__main-top--font,
  .option4__main-top--font {
    font-size: 16px;
    font-weight: bold;
    margin-top: 55px; }
    @media (max-width: 1279.98px) {
      .option1__main-top--font,
      .option2__main-top--font,
      .option3__main-top--font,
      .option4__main-top--font {
        font-size: 28px;
        margin-top: 0; } }
    @media (max-width: 699.98px) {
      .option1__main-top--font,
      .option2__main-top--font,
      .option3__main-top--font,
      .option4__main-top--font {
        font-size: 12px;
        line-height: 14px;
        margin-top: -5px; } }
  .option1__main-top--text,
  .option2__main-top--text,
  .option3__main-top--text,
  .option4__main-top--text {
    font-size: 20px;
    font-weight: 500;
    margin-top: 15px; }
    @media (max-width: 1279.98px) {
      .option1__main-top--text,
      .option2__main-top--text,
      .option3__main-top--text,
      .option4__main-top--text {
        font-size: 26px;
        margin-top: -6px; } }
    @media (max-width: 699.98px) {
      .option1__main-top--text,
      .option2__main-top--text,
      .option3__main-top--text,
      .option4__main-top--text {
        font-size: 12px;
        line-height: 14px; } }
    .option1__main-top--text span,
    .option2__main-top--text span,
    .option3__main-top--text span,
    .option4__main-top--text span {
      font-size: 35px;
      font-weight: bold; }
      @media (max-width: 1279.98px) {
        .option1__main-top--text span,
        .option2__main-top--text span,
        .option3__main-top--text span,
        .option4__main-top--text span {
          font-size: 42px;
          margin-left: 15px; } }
      @media (max-width: 699.98px) {
        .option1__main-top--text span,
        .option2__main-top--text span,
        .option3__main-top--text span,
        .option4__main-top--text span {
          font-size: 31px;
          margin-left: 10px; } }
  .option1__main-top--wrap,
  .option2__main-top--wrap,
  .option3__main-top--wrap,
  .option4__main-top--wrap {
    display: flex;
    align-items: flex-start;
    justify-content: center; }
    .option1__main-top--wrap p,
    .option2__main-top--wrap p,
    .option3__main-top--wrap p,
    .option4__main-top--wrap p {
      font-size: 20px;
      font-weight: 500;
      margin-top: 15px; }
      @media (max-width: 1279.98px) {
        .option1__main-top--wrap p,
        .option2__main-top--wrap p,
        .option3__main-top--wrap p,
        .option4__main-top--wrap p {
          font-size: 26px;
          margin-top: -6px; } }
      @media (max-width: 699.98px) {
        .option1__main-top--wrap p,
        .option2__main-top--wrap p,
        .option3__main-top--wrap p,
        .option4__main-top--wrap p {
          font-size: 12px;
          line-height: 14px; } }
      .option1__main-top--wrap p span,
      .option2__main-top--wrap p span,
      .option3__main-top--wrap p span,
      .option4__main-top--wrap p span {
        font-size: 35px;
        font-weight: bold; }
        @media (max-width: 1279.98px) {
          .option1__main-top--wrap p span,
          .option2__main-top--wrap p span,
          .option3__main-top--wrap p span,
          .option4__main-top--wrap p span {
            font-size: 42px;
            margin-left: 15px; } }
        @media (max-width: 699.98px) {
          .option1__main-top--wrap p span,
          .option2__main-top--wrap p span,
          .option3__main-top--wrap p span,
          .option4__main-top--wrap p span {
            font-size: 31px; } }
  @media (max-width: 1279.98px) {
    .option1__main-bottom,
    .option2__main-bottom,
    .option3__main-bottom,
    .option4__main-bottom {
      margin-top: 20px;
      display: flex;
      justify-content: center; } }
  @media (max-width: 699.98px) {
    .option1__main-bottom,
    .option2__main-bottom,
    .option3__main-bottom,
    .option4__main-bottom {
      margin-top: 25px; } }
  .option1__main-bottom--font,
  .option2__main-bottom--font,
  .option3__main-bottom--font,
  .option4__main-bottom--font {
    font-size: 16px;
    font-weight: bold;
    margin-top: 50px; }
    @media (max-width: 1279.98px) {
      .option1__main-bottom--font,
      .option2__main-bottom--font,
      .option3__main-bottom--font,
      .option4__main-bottom--font {
        font-size: 28px;
        justify-content: center;
        margin-top: 0; } }
    @media (max-width: 699.98px) {
      .option1__main-bottom--font,
      .option2__main-bottom--font,
      .option3__main-bottom--font,
      .option4__main-bottom--font {
        font-size: 12px;
        line-height: 14px;
        margin-top: -5px; } }
  @media (max-width: 1279.98px) {
    .option1__main-bottom,
    .option2__main-bottom,
    .option3__main-bottom,
    .option4__main-bottom {
      display: flex; } }
  .option1__main-bottom--text,
  .option2__main-bottom--text,
  .option3__main-bottom--text,
  .option4__main-bottom--text {
    font-size: 20px;
    font-weight: 500;
    margin-top: 18px; }
    @media (max-width: 1279.98px) {
      .option1__main-bottom--text,
      .option2__main-bottom--text,
      .option3__main-bottom--text,
      .option4__main-bottom--text {
        font-size: 26px;
        margin-top: -6px; } }
    @media (max-width: 699.98px) {
      .option1__main-bottom--text,
      .option2__main-bottom--text,
      .option3__main-bottom--text,
      .option4__main-bottom--text {
        font-size: 12px;
        line-height: 14px; } }
    .option1__main-bottom--text span,
    .option2__main-bottom--text span,
    .option3__main-bottom--text span,
    .option4__main-bottom--text span {
      font-size: 35px;
      font-weight: bold; }
      @media (max-width: 1279.98px) {
        .option1__main-bottom--text span,
        .option2__main-bottom--text span,
        .option3__main-bottom--text span,
        .option4__main-bottom--text span {
          font-size: 42px;
          margin-left: 15px; } }
      @media (max-width: 699.98px) {
        .option1__main-bottom--text span,
        .option2__main-bottom--text span,
        .option3__main-bottom--text span,
        .option4__main-bottom--text span {
          font-size: 31px;
          margin-left: 12px; } }

.option1 {
  width: 170px;
  margin-right: 70px; }
  @media (max-width: 1279.98px) {
    .option1 {
      width: 50%; } }
  @media (max-width: 1239.98px) {
    .option1 {
      margin-right: 60px; } }
  @media (max-width: 699.98px) {
    .option1 {
      margin-right: 23px; } }
  .option1__title-wrap {
    margin-top: 40px; }
    @media (max-width: 699.98px) {
      .option1__title-wrap {
        margin-top: 25px; } }
  @media (max-width: 699.98px) {
    .option1__main-top {
      margin-top: 28px; } }
  .option1__main-top--font {
    margin-top: 40px; }
    @media (max-width: 1279.98px) {
      .option1__main-top--font {
        margin-top: -4px; } }
  .option1__main-bottom {
    margin-top: 20px; }

.option2 {
  width: 105px;
  margin-right: 85px; }
  .option2__title-wrap {
    margin-top: 60px; }
    @media (max-width: 1279.98px) {
      .option2__title-wrap {
        margin-top: 40px; } }
    @media (max-width: 699.98px) {
      .option2__title-wrap {
        margin-top: 25px; } }
  @media (max-width: 1279.98px) {
    .option2__main-bottom--text span {
      margin-left: 18px;
      margin-right: 10px; } }
  @media (max-width: 1279.98px) {
    .option2 {
      width: 50%;
      margin-right: 0; } }

.option3 {
  width: 80px;
  margin-right: 85px; }
  .option3__title-wrap {
    margin-top: 60px; }
    @media (max-width: 699.98px) {
      .option3__title-wrap {
        margin-top: 30px;
        margin-left: 27px; } }
  @media (max-width: 699.98px) {
    .option3__main-top {
      margin-left: 10px; } }
  @media (max-width: 699.98px) {
    .option3__main-bottom {
      margin-left: 8px; } }
  .option3__main-bottom--font {
    margin-left: 10px;
    margin-right: 10px; }
    @media (max-width: 699.98px) {
      .option3__main-bottom--font {
        margin-left: 0px; } }
  @media (max-width: 1279.98px) {
    .option3__main-bottom--text span {
      margin-left: 15px; } }
  @media (max-width: 699.98px) {
    .option3__main-bottom--text span {
      margin-left: 5px; } }
  @media (max-width: 1279.98px) {
    .option3 {
      width: 50%;
      margin-right: 40px; } }

.option4 {
  width: 150px;
  width: 150px; }
  @media (max-width: 1279.98px) {
    .option4 {
      width: 50%; } }
  .option4__title-wrap {
    margin-top: 60px; }
    @media (max-width: 699.98px) {
      .option4__title-wrap {
        margin-top: 30px; } }
  @media (max-width: 1279.98px) {
    .option4__main-top {
      justify-content: center;
      margin-left: -20px; } }
  @media (max-width: 699.98px) {
    .option4__main-top {
      margin-left: 3px; } }
  @media (max-width: 1279.98px) {
    .option4__main-bottom {
      justify-content: center;
      margin-left: -100px; } }
  @media (max-width: 699.98px) {
    .option4__main-bottom {
      margin-left: -60px;
      margin-top: 20px; }
      .option4__main-bottom--text span {
        margin-left: 16px; } }
  @media (max-width: 1279.98px) {
    .option4 {
      width: 50%; } }
  .option4 .blue-oval {
    margin-top: 20px; }
    @media (max-width: 1279.98px) {
      .option4 .blue-oval {
        margin-top: 6px; } }
    @media (max-width: 699.98px) {
      .option4 .blue-oval {
        margin-top: -5px !important; } }

.section3-img2 {
  margin: 30px 0 90px;
  height: 220px;
  position: relative; }
  @media (max-width: 1279.98px) {
    .section3-img2 {
      width: 100%;
      margin-top: 200px; } }
  @media (max-width: 1279.98px) {
    .section3-img2 {
      width: 100%;
      margin-top: 200px; } }
  @media (max-width: 699.98px) {
    .section3-img2 {
      margin: 20px auto 165px;
      width: 350px;
      height: auto; } }
  .section3-img2__content {
    display: block;
    position: absolute;
    right: calc(50% - 300px); }
    @media (max-width: 699.98px) {
      .section3-img2__content {
        width: 350px;
        right: 25px; } }
    @media (max-width: 434.98px) {
      .section3-img2__content {
        width: 320px;
        right: 25px; } }

.section3-flow-wrap {
  position: relative; }

.section3-flow {
  position: relative;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 1279.98px) {
    .section3-flow {
      position: relative; } }

.section3-flow-title {
  margin-bottom: 35px; }
  @media (max-width: 699.98px) {
    .section3-flow-title {
      margin-bottom: 15px; } }

.section3-flow-main1,
.section3-flow-main2,
.section3-flow-main3,
.section3-flow-main4 {
  max-width: 860px;
  height: auto;
  background-color: #fff;
  border-radius: 100px;
  position: relative;
  padding-top: 7px;
  margin-bottom: 25px; }
  @media (max-width: 1279.98px) {
    .section3-flow-main1,
    .section3-flow-main2,
    .section3-flow-main3,
    .section3-flow-main4 {
      margin: 7px auto 25px;
      width: calc(100% - 40px); } }
  @media (max-width: 699.98px) {
    .section3-flow-main1,
    .section3-flow-main2,
    .section3-flow-main3,
    .section3-flow-main4 {
      max-width: 380px;
      widows: 90%;
      margin-top: -10px; } }
  .section3-flow-main1:after,
  .section3-flow-main2:after,
  .section3-flow-main3:after,
  .section3-flow-main4:after {
    content: url("../assets/section3/section3-12.png");
    width: 35px;
    height: auto;
    bottom: -21px;
    left: 170px;
    z-index: 3;
    position: absolute; }
    @media (max-width: 699.98px) {
      .section3-flow-main1:after,
      .section3-flow-main2:after,
      .section3-flow-main3:after,
      .section3-flow-main4:after {
        content: url("../assets/section3/section3-16.svg");
        left: 79px;
        bottom: -18px; } }
    .section3-flow-main1:after::after,
    .section3-flow-main2:after::after,
    .section3-flow-main3:after::after,
    .section3-flow-main4:after::after {
      content: url("../assets/section3/section3-11.svg");
      position: absolute;
      width: 35px;
      height: auto;
      bottom: -22px;
      left: 170px; }
      @media (max-width: 699.98px) {
        .section3-flow-main1:after::after,
        .section3-flow-main2:after::after,
        .section3-flow-main3:after::after,
        .section3-flow-main4:after::after {
          content: url("../assets/section3/section3-14.svg"); } }
  .section3-flow-main1__number,
  .section3-flow-main2__number,
  .section3-flow-main3__number,
  .section3-flow-main4__number {
    position: absolute;
    color: #73CBE6;
    font-family: "Roboto", sans-serif;
    font-size: 45px;
    z-index: 3; }
    @media (max-width: 699.98px) {
      .section3-flow-main1__number,
      .section3-flow-main2__number,
      .section3-flow-main3__number,
      .section3-flow-main4__number {
        font-size: 34px;
        z-index: 3;
        left: 5px;
        top: 4px; } }
  .section3-flow-main1__flex,
  .section3-flow-main2__flex,
  .section3-flow-main3__flex,
  .section3-flow-main4__flex {
    display: flex;
    align-items: center;
    padding: 0 170px 0 130px;
    height: 120px;
    position: relative;
    z-index: 2;
    background-color: #fff;
    width: 860px;
    border-radius: 100px; }
    @media (max-width: 1279.98px) {
      .section3-flow-main1__flex,
      .section3-flow-main2__flex,
      .section3-flow-main3__flex,
      .section3-flow-main4__flex {
        width: 100%;
        margin: 0 auto; } }
    @media (max-width: 1239.98px) {
      .section3-flow-main1__flex,
      .section3-flow-main2__flex,
      .section3-flow-main3__flex,
      .section3-flow-main4__flex {
        padding: 0 60px 0 130px; } }
    @media (max-width: 699.98px) {
      .section3-flow-main1__flex,
      .section3-flow-main2__flex,
      .section3-flow-main3__flex,
      .section3-flow-main4__flex {
        flex-direction: column;
        padding: 0;
        height: 130px; } }
    .section3-flow-main1__flex--left,
    .section3-flow-main2__flex--left,
    .section3-flow-main3__flex--left,
    .section3-flow-main4__flex--left {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      position: relative;
      display: inline-block;
      margin-right: 85px;
      line-height: 30px; }
      @media (max-width: 1239.98px) {
        .section3-flow-main1__flex--left,
        .section3-flow-main2__flex--left,
        .section3-flow-main3__flex--left,
        .section3-flow-main4__flex--left {
          font-size: 18px;
          margin-right: 65px; } }
      @media (max-width: 699.98px) {
        .section3-flow-main1__flex--left,
        .section3-flow-main2__flex--left,
        .section3-flow-main3__flex--left,
        .section3-flow-main4__flex--left {
          font-size: 17px;
          margin-right: 0;
          margin-top: 2px;
          line-height: 25px;
          margin-right: 0 !important; } }
    .section3-flow-main1__flex--right,
    .section3-flow-main2__flex--right,
    .section3-flow-main3__flex--right,
    .section3-flow-main4__flex--right {
      width: 455px;
      font-family: "Noto Sans JP", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      position: relative;
      text-align: left;
      white-space: initial; }
      @media (max-width: 1239.98px) {
        .section3-flow-main1__flex--right,
        .section3-flow-main2__flex--right,
        .section3-flow-main3__flex--right,
        .section3-flow-main4__flex--right {
          font-size: 14px;
          line-height: 20px; } }
      @media (max-width: 699.98px) {
        .section3-flow-main1__flex--right,
        .section3-flow-main2__flex--right,
        .section3-flow-main3__flex--right,
        .section3-flow-main4__flex--right {
          font-size: 15px;
          line-height: 22px;
          margin-top: 15px;
          width: 305px;
          display: block;
          margin: 10px auto 0;
          letter-spacing: 1px; } }
      @media (max-width: 399.98px) {
        .section3-flow-main1__flex--right,
        .section3-flow-main2__flex--right,
        .section3-flow-main3__flex--right,
        .section3-flow-main4__flex--right {
          font-size: 13px;
          line-height: 20px;
          margin-top: 15px;
          width: 270px;
          display: block;
          margin: 10px auto 0;
          letter-spacing: 1px; } }

.section3-flow-main1 {
  margin-top: 5px; }
  .section3-flow-main1__background {
    z-index: 4; }

.section3-flow-main2__background {
  z-index: 3; }

.section3-flow-main2__flex {
  height: 196.85px;
  padding: 0 60px 0 100px; }
  @media (max-width: 699.98px) {
    .section3-flow-main2__flex {
      padding: 0; } }
  .section3-flow-main2__flex--left {
    margin-right: 55px; }
    @media (max-width: 699.98px) {
      .section3-flow-main2__flex--left {
        margin-top: 10px; } }

@media (max-width: 699.98px) {
  .section3-flow-main2__number {
    left: 5px;
    top: 8px; } }

.section3-flow-main3__background {
  z-index: 2; }

.section3-flow-main3__flex {
  padding-right: 75px; }
  @media (max-width: 699.98px) {
    .section3-flow-main3__flex {
      height: 169px;
      padding: 0; } }
  .section3-flow-main3__flex--left {
    margin-right: 55px; }
    @media (max-width: 699.98px) {
      .section3-flow-main3__flex--left {
        margin-top: 10px; } }

.section3-flow-main4__flex {
  padding: 0 65px 0 120px; }
  @media (max-width: 699.98px) {
    .section3-flow-main4__flex {
      padding: 0;
      height: 120px; } }
  .section3-flow-main4__flex--left {
    margin-right: 55px; }
    @media (max-width: 699.98px) {
      .section3-flow-main4__flex--left {
        margin-right: 0;
        margin-top: 10px; } }

.section3-flow-main4:after {
  display: none; }

.section3-flow-main4 .section3-flow-background:after {
  display: none; }

@media (max-width: 699.98px) {
  .section3-flow-main4__number {
    left: 5px;
    top: 4px; } }

.section3-flow-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 12px;
  border-radius: 100px;
  z-index: 1;
  background-color: #c8eefa;
  left: 0; }
  @media (max-width: 699.98px) {
    .section3-flow-background {
      top: 8px; } }
  .section3-flow-background::after {
    content: url("../assets/section3/section3-11.png");
    position: absolute;
    width: 35px;
    height: auto;
    bottom: -22px;
    left: 170px;
    z-index: 3; }
    @media (max-width: 699.98px) {
      .section3-flow-background::after {
        content: url("../assets/section3/section3-14.svg");
        left: 77px;
        bottom: -18px; } }

.tax-caution::after {
  content: '表示価格は全て税別です';
  position: absolute;
  right: 0;
  bottom: -24px;
  font-weight: 700; }
  @media (max-width: 699.98px) {
    .tax-caution::after {
      font-size: 1.2rem;
      bottom: -16px; } }

.section4 {
  margin-top: 180px;
  padding-bottom: 40px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  padding-top: 20px; }
  @media (max-width: 699.98px) {
    .section4 {
      margin-top: 110px;
      padding-bottom: 0px; } }

.section4-title__main {
  font-size: 40px;
  font-weight: bold;
  text-align: center; }
  @media (max-width: 699.98px) {
    .section4-title__main {
      font-size: 26px; } }

.section4-title__sub {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  color: #A7C7F2;
  text-align: center;
  margin-top: 20px;
  font-weight: bold; }
  @media (max-width: 699.98px) {
    .section4-title__sub {
      font-size: 17px;
      margin-top: 10px; } }
  .section4-title__sub--title1 {
    margin-right: 5px; }
    @media (max-width: 699.98px) {
      .section4-title__sub--title1 {
        margin-right: 1px; } }
  .section4-title__sub--title2 {
    margin-left: 5px;
    margin-right: 0; }
    @media (max-width: 699.98px) {
      .section4-title__sub--title2 {
        margin-left: 2px; } }

.section4-main {
  position: relative; }
  .section4-main__img {
    width: 100%;
    margin-top: 35px; }
    @media (max-width: 699.98px) {
      .section4-main__img {
        display: none; } }
    .section4-main__img--sp {
      display: none; }
      @media (max-width: 699.98px) {
        .section4-main__img--sp {
          margin-top: 20px;
          display: block;
          width: 100%; } }
  .section4-main__text {
    margin-top: 60px;
    text-align: center; }
    @media (max-width: 699.98px) {
      .section4-main__text {
        margin-top: 35px; } }
    .section4-main__text--font1 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 30px; }
      @media (max-width: 699.98px) {
        .section4-main__text--font1 {
          margin-bottom: 20px; } }
    .section4-main__text--font2 {
      font-size: 17px;
      line-height: 27px;
      font-weight: 500; }
      @media (max-width: 699.98px) {
        .section4-main__text--font2 {
          font-size: 14px;
          line-height: 19px; } }

.sp-br {
  display: none; }
  @media (max-width: 699.98px) {
    .sp-br {
      display: block; } }

.ssp-br {
  display: none; }
  @media (max-width: 399.98px) {
    .ssp-br {
      display: block; } }

body {
  background-color: #ffffff; }

.main-wrapper {
  width: 100%;
  display: flex; }
  .main-wrapper .content-wrapper {
    width: calc(100% - 352px);
    height: auto; }
    @media (max-width: 999.98px) {
      .main-wrapper .content-wrapper {
        width: 100%; } }

.contact-wrapper {
  width: 352px;
  height: 100vw; }
  @media (max-width: 999.98px) {
    .contact-wrapper {
      position: fixed;
      z-index: 999; } }
