.section1 {
    width: 100%;
    overflow-x: hidden;
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
    @include media-breakpoint-down(sp) {
        height: 1790px;
    }
}

#top-change {
    @include media-breakpoint-down(xxl) {
        top: -80px !important;
    }
    @include media-breakpoint-down(sp) {
        top: 0px !important;
    }
}

#top-change2 {
    top: -170px;
    @include media-breakpoint-down(xxl) {
        top: -150px;
    }
    @include media-breakpoint-down(sp) {
        top: 0px;
    }
}
#top-change3 {
    top: -285px;
    @include media-breakpoint-down(txl) {
        top: -260px;
    }
    @include media-breakpoint-down(xxl) {
        top: -225px;
    }
    @include media-breakpoint-down(sp) {
        top: 0px;
    }
}

.section1-title {
    font-size: 48px;
    font-weight: bold;
    font-family: $font-Yugothic;
    text-align: center;
    margin: 50px auto 10px;
    position: relative;
    display: table;
    @include media-breakpoint-down(sp) {
        font-size: 32px;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    &:before {
        content: url("../assets/section1/section1-05.png");
        position: absolute;
        left: -60px;
        top: -5px;
        @include media-breakpoint-down(sp) {
            content: url("../assets/section1/section1-09.png");
            position: absolute;
            left: -45px;
        }
    }
    &:after {
        content: url("../assets/section1/section1-06.png");
        position: absolute;
        right: -75px;
        top: -5px;
        @include media-breakpoint-down(sp) {
            content: url("../assets/section1/section1-10.png");
            position: absolute;
            right: -48px;
        }
    }
}

.section1-main1 {
    position: relative;
    width: 95%;
    height: 530px;
    margin-top: 60px;
    clip-path: polygon(0 0, 88% 12%, 100% 83%, 0 100%);
    background-color: $color-pink;

    @include media-breakpoint-down(txl) {
        height: 620px;
        width: 974px;
    }

    @include media-breakpoint-down(sp) {
        width: 604px;
        height: 468px;
        margin-bottom: -68px;
        left: calc(50% - 300px);
        margin-top: 40px;
    }

    &__background {
        position: absolute;
        right: calc(40% - 365px);
        width: 100%;
        overflow-y: hidden;
        top: calc(-5%);
        @include media-breakpoint-down(txl) {
            right: calc(50% - 465px);
            width: auto;
            top: auto;
            height: auto;
            overflow-y: auto;
        }
        @include media-breakpoint-down(sp) {
            display: none;
        }
        &--sp {
            display: none;
            @include media-breakpoint-down(sp) {
                display: block;
                position: absolute;
                @include X_middle();
            }
        }
    }
}

.section1-main2 {
    position: relative;
    right: -5%;
    height: 542px;
    clip-path: polygon(0 17%, 99% 0, 100% 100%, 11% 89%);
    background-color: $color-w-blue;
    top: -100px;

    @include media-breakpoint-down(txl) {
        height: 620px;
        width: 974px;
        top: -90px;
        left: calc(50% - 465px);
    }

    @include media-breakpoint-down(sp) {
        width: 604px;
        height: 468px;
        margin-bottom: -51px;
        left: calc(50% - 300px);
    }

    &__background {
        position: absolute;
        left: calc(40% - 365px);
        width: 100%;
        overflow-y: hidden;
        top: calc(-5%);
        @include media-breakpoint-down(txl) {
            position: absolute;
            left: calc(50% - 465px);
            width: auto;
            top: auto;
            height: auto;
            overflow-y: auto;
        }
        @include media-breakpoint-down(sp) {
            display: none;
        }
        &--sp {
            display: none;
            @include media-breakpoint-down(sp) {
                display: block;
                position: absolute;
                right: calc(50% - 275px);
            }
        }
    }
}

.section1-first,
.section1-second,
.section1-third,
.section1-fourth {
    position: absolute;
    right: calc(50% - 130px);
    display: flex;
    top: 21%;
    @include media-breakpoint-down(txl) {
        top: 95px;
    }
    @include media-breakpoint-down(xl) {
        top: 110px;
    }
    @include media-breakpoint-down(sp) {
        top: 80px;
        width: 318px;
        right: calc(50% - 159px);
    }

    &__left {
        display: flex;
        flex-direction: column;
        text-align: center;
        @include media-breakpoint-down(sp) {
            margin-right: -42px;
        }

        &--point {
            font-family: $font-roboto;
            color: $color-white;
            font-size: 24px;
            font-weight: 400;
            @include media-breakpoint-down(sp) {
                font-size: 13px;
            }
        }

        &--number {
            font-family: $font-roboto;
            font-weight: 400;
            color: $color-white;
            font-size: 70px;
            margin-top: 8px;
            @include media-breakpoint-down(sp) {
                font-size: 40px;
            }
        }
    }

    &__right {
        width: 465px;
        margin-top: -3px;

        &--text1 {
            font-size: 35px;
            font-weight: bold;
            font-family: $font-Yugothic;
            text-align: center;
            line-height: 40px;
            margin-left: 40px;
            @include media-breakpoint-down(sp) {
                font-size: 21px;
                line-height: 1em;
                margin-top: -2px;
                margin-left: 12px;
            }
        }

        &--text2 {
            font-size: 45px;
            line-height: 70px;
            margin-left: 15px;
            font-family: $font-Yugothic;
            font-weight: bold;
            text-align: center;
            opacity: 0;
            transform: translate(0, 20px);
            transition: 0.5s;
            @include media-breakpoint-down(sp) {
                font-size: 27px;
                line-height: 1em;
                margin-top: 15px;
            }
        }

        &--text3 {
            font-size: 18px;
            font-weight: 500;
            font-family: $font-Yugothic;
            line-height: 24px;
            margin-top: 10px;
            letter-spacing: -1px;
            @include media-breakpoint-down(sp) {
                display: flex;
                width: 310px;
                font-size: 15px;
                line-height: 21px;
                margin: 25px 0 0 -2px;
                letter-spacing: 0.4px;
            }
        }
    }

    &__bottom {
        margin-top: 30px;
        margin-left: 60px;
        @include media-breakpoint-down(sp) {
            margin-top: 7px;
        }

        &--img1 {
            @include media-breakpoint-down(sp) {
                display: none;
            }
        }
        &--img1-sp {
            display: none;
            @include media-breakpoint-down(sp) {
                display: block;
            }
        }
    }
}

.section1-second,
.section1-fourth {
    left: calc(50% - 145px);
    @include media-breakpoint-down(txl) {
        top: 120px;
    }
    @include media-breakpoint-down(xl) {
        top: 120px;
        left: calc(50% - 260px);
    }
    @include media-breakpoint-down(sp) {
        top: 90px;
        left: calc(50% - 159px);
    }
    @include media-breakpoint-down(sp) {
        top: 90px;
        left: calc(50% - 159px);
    }
}

#top-change2 {
    margin-top: 15px;
    @include media-breakpoint-down(sp) {
        margin-top: 0px;
    }
}

.section1-second {
    &__right {
        &--text1 {
            margin-left: 70px;
            white-space: nowrap;
        }
        &--text2 {
            margin-left: 58px;
            @include media-breakpoint-down(sp) {
                margin-top: 19px;
            }
        }
    }
    &__bottom {
        margin-top: 0;
        margin-left: 60px;
        @include media-breakpoint-down(sp) {
            margin-top: 19px;
            margin-left: 50px;
        }
    }
}
.section1-third {
    &__right {
        &--text1 {
            margin-left: 20px;
            white-space: nowrap;
            @include media-breakpoint-down(sp) {
                margin-left: 35px;
            }
        }
        &--text2 {
            margin-left: 45px;
            @include media-breakpoint-down(sp) {
                margin-left: 40px;
            }
        }
    }
    &__bottom {
        margin-top: 55px;
        margin-left: -15px;
        @include media-breakpoint-down(sp) {
            margin-top: 20px;
            margin-left: 20px;
        }
    }
}
.section1-fourth {
    &__right {
        &--text1 {
            margin-left: 80px;
            white-space: nowrap;
            @include media-breakpoint-down(ssl) {
                margin-left: 60px;
            }
            @include media-breakpoint-down(sp) {
                margin-left: 65px;
            }
        }
        &--text2 {
            margin-left: 40px;
            @include media-breakpoint-down(sp) {
                margin-left: 70px;
            }
        }
    }
    &__bottom {
        margin-top: 30px;
        margin-left: 25px;
        @include media-breakpoint-down(sp) {
            margin-top: 28px;
        }
    }
}

.is-active {
    transform: translate(0, 0);
    opacity: 1;
}

.none-sp {
    @include media-breakpoint-down(sp) {
        display: none;
    }
}
