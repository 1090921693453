@import "./header";
@import "./footer";
@import "./contact";
@import "./fv";
@import "./section1";
@import "./section2";
@import "./section3";
@import "./section4";

body {
    background-color: $color-white;
}

.main-wrapper {
    width: 100%;
    display: flex;

    .content-wrapper {
        width: calc(100% - 352px);
        height: auto;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
}

.contact-wrapper {
    width: 352px;
    height: 100vw;

    @include media-breakpoint-down(lg) {
        position: fixed;
        z-index: 999;
    }
}
