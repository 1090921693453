//　メインカラー
$color-black: #080808;
$color-white: #ffffff;
$color-btn: #ED3737;
$color-require: #727272;
$color-textarea: #9D9D9D;
$color-text: #222222;
$no-active: #EBDFDF;
$color-white-blue: #73CBE6;
$color-blue: #2BAAD1;
$color-dark-blue: #A7C7F2;
$color-pink: #FFBCBC;
$color-w-blue: #BCEFFF;