.section4 {
    margin-top: 180px;
    padding-bottom: 40px;
    font-family: $font-Yugothic;
    padding-top: 20px;
    @include media-breakpoint-down(sp) {
        margin-top: 110px;
        padding-bottom: 0px;
    }
}

.section4-title {
    &__main {
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        @include media-breakpoint-down(sp) {
            font-size: 26px;
        }
    }
    &__sub {
        font-family: $font-roboto;
        font-size: 30px;
        color: $color-dark-blue;
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
        @include media-breakpoint-down(sp) {
            font-size: 17px;
            margin-top: 10px;
        }

        &--title1 {
            margin-right: 5px;
            @include media-breakpoint-down(sp) {
                margin-right: 1px;
            }
        }

        &--title2 {
            margin-left: 5px;
            margin-right: 0;
            @include media-breakpoint-down(sp) {
                margin-left: 2px;
            }
        }
    }
}

.section4-main {
    position: relative;
    &__img {
        width: 100%;
        margin-top: 35px;
       
        @include media-breakpoint-down(sp) {
            display: none;
        }

        &--sp {
            display: none;
            @include media-breakpoint-down(sp) {
                margin-top: 20px;
                display: block;
                width: 100%;
            }
        }
    }

    &__text {
        margin-top: 60px;
        text-align: center;
        @include media-breakpoint-down(sp) {
            margin-top: 35px;
        }

        &--font1 {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 30px;
            @include media-breakpoint-down(sp) {
               margin-bottom: 20px;
            }
        }
        &--font2 {
            font-size: 17px;
            line-height: 27px;
            font-weight: 500;
            @include media-breakpoint-down(sp) {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}

.sp-br {
    display: none;
    @include media-breakpoint-down(sp) {
        display: block;
    }
}

.ssp-br{
    display: none;
    @include media-breakpoint-down(ssp){
        display: block;
    }
}
