*,
*::after,
*::before {
  box-sizing: border-box;
}
html {
  font-size: 62.5%; //1rem = 16px
}

body {
  font-family: $font-Yugothic;
  font-size: $font-normal;
  font-weight: $font-weight-normal;
  color: $color-text;
}

.top-container {
  width: $top-container;
  margin: 0 auto;
  @include media-breakpoint-down(xl) {
    width: 90%;
  }
}

.container {
  width: $container;
  margin: 0 auto;
  @include media-breakpoint-down(lg) {
    width: 90%;
  }
}

.right-container {
  padding-left: $side;
  @include media-breakpoint-down(lg) {
    padding-left: 5%;
  }
}

.left-container {
  padding-right: $side;
  @include media-breakpoint-down(lg) {
    padding-right: 5%;
  }
}
.flex-box {
  display: flex;
}

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.btn {
  width: 212px;
  height: 53px;
  background-color: $color-btn;
  border-radius: 25px;
  box-shadow: 0 3px 6px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  border: 0px;
  letter-spacing: 1px;
  margin-top: 140px;
}

.require {
  width: 36px;
  height: 18px;
  border-radius: 21px;
  background-color: $color-require;
  color: white;
  font-size: 9px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.blue-oval {
  font-family: $font-Yugothic;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
  padding: 7px 11px;
  background-color: $color-white-blue;
  color: $color-white;
  margin: 5px 0 0 6px;
  @include media-breakpoint-down(sp) {
    font-size: 11px;
    margin-top: -4px;
  }
}

.blue-oval2 {
  font-family: $font-Yugothic;
  font-size: 15px;
  font-weight: bold;
  border-radius: 15px;
  padding: 7px 16px;
  background-color: $color-white-blue;
  color: $color-white;
  white-space: nowrap;
  margin: 5px 0 0 6px;
  @include media-breakpoint-down(sp) {
    font-size: 11px;
    padding: 7px 14.5px;
    margin-top: 0px;
  }
}

.sp-no-br {
  display: block;
  @include media-breakpoint-down(sp) {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  60% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translate(-50%, 100px);
    opacity: 0;
  }

  100% {
    transform: translateY(-50%, 0);
    opacity: 1;
  }
}

@keyframes slideUpMin {
  0% {
    transform: translate(-50%, 40px);
    opacity: 0;
  }

  100% {
    transform: translateY(-50%, 0);
    opacity: 1;
  }
}
