@mixin NotoSanJp($type) {
        font-family: $font-notoSan;
        font-weight: $type;
}

@mixin Title($fweight: bold, $fSize: 22px) {
        font-weight: $fweight;
        font-size: $fSize;
}

@mixin Y_middle($normalY: 50%, $transY: translateY(-50%)) {
        position: absolute;
        top: $normalY;
        transform: $transY;
}

@mixin X_middle($normalX: 50%, $transX: translateX(-50%)) {
        position: absolute;
        left: $normalX;
        transform: $transX;
}

@mixin Block_center($center-margin: 0 auto) {
        margin: $center-margin;
        display: block;
}

@mixin Top_Section_Title($fweight: bold, $fSize: 30px) {
        font-weight: $fweight;
        font-size: $fSize;
}

@mixin Hamburger-border() {
        content: "";
        display: block;
        height: 2px;
        width: 25px;
        position: absolute;
        background-color: $color-sp-menu;
}

@mixin Speech-balloon-font() {
        font-family: $font-Yugothic;
        font-weight: bold;
        font-size: 18px;
        color: $color-white;
        text-align: center;
        position: relative;
        text-align: center;
        white-space: nowrap;
        letter-spacing: -1.5px;
}

@mixin Speech-balloon-sfont() {
        font-family: $font-Yugothic;
        font-weight: bold;
        font-size: 12px;
        color: $color-white;
        text-align: center;
        position: relative;
        text-align: center;
        white-space: nowrap;
        line-height: 16px;
}