.section3 {
    position: relative;
    margin-top: 360px;
    padding-top: 55px;
    font-family: $font-Yugothic;
    white-space: nowrap;
    background-color: #f0fbff;
    margin-bottom: 300px;

    @include media-breakpoint-down(sp) {
        margin-top: 200px;
    }
}

.section3-background {
    width: 100%;
    position: absolute;
    -ms-zoom-animation: -1;
}

.section3-background2 {
    position: absolute;
    width: 100%;
    z-index: -1;
}

.section3-price-title,
.section3-flow-title-wrap {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-top: 0px;
    padding-top: 20px;
    @include media-breakpoint-down(sp) {
        font-size: 26px;
    }
}

.section3-price-title{
    @include X_middle();
}
.section3-price-title2{
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    @include media-breakpoint-down(sp) {
        font-size: 26px;
    }
}

.section3-flow-title-wrap {
    margin-bottom: 20px;
}

.section3-price-subtitle,
.section3-flow-subtitle {
    font-family: $font-roboto;
    font-size: 30px;
    color: $color-white-blue;
    text-align: center;
    margin-top: 20px;
    @include media-breakpoint-down(sp) {
        font-size: 17px;
        margin-top: 10px;
    }

    &__span {
        margin-right: 12px;
    }

    :nth-child(2) {
        margin-left: 12px;
        margin-right: 0;
        @include media-breakpoint-down(sp) {
            margin-left: 0;
        }
    }
}

.ad-wrap {
    display: flex;
    margin-top: 40px;
    position: relative;
    height: 510px;
    width: 875px;
    margin: 40px auto 0;
    @include media-breakpoint-down(sxl) {
        width: auto;
    }
    @include media-breakpoint-down(sp) {
        height: auto;
        margin-top: 30px;
    }
}

.sns-ad-wrap,
.listing-ad-wrap {
    height: 510px;
    border-radius: 20px;
    background-color: $color-white;
    padding: 50px 38px 60px 38px;
    position: absolute;
    @include media-breakpoint-down(sxl) {
        height: auto;
    }
    @include media-breakpoint-down(sp) {
        height: 295px;
        padding: 20px 25px 20px;
    }

    &__title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 25px;
        @include media-breakpoint-down(sxl) {
            font-size: 31px;
        }
        @include media-breakpoint-down(sp) {
            font-size: 18px;
            margin-bottom: 15px;
        }

        &--border {
            background-color: $color-blue;
            height: 3px;
            width: 100%;
        }
    }

    &__main {
        margin-top: 50px;
        @include media-breakpoint-down(sxl) {
            margin-top: 30px;
        }
        @include media-breakpoint-down(sp) {
            margin-top: 5px;
        }
    }
}

.sns-ad-wrap {
    top: 0;
    right: calc(50% + 15px);
    @include media-breakpoint-down(sxl) {
        left: 25px;
        width: calc(100% - 50px);
    }
    @include media-breakpoint-down(sp) {
        max-width: 370px;
        width: 90%;
        @include X_middle();
    }
}

.listing-ad-wrap {
    top: 0;
    left: calc(50% + 15px);
    @include media-breakpoint-down(sxl) {
        top: 525px;
        left: 25px;
        width: calc(100% - 50px);
    }
    @include media-breakpoint-down(sp) {
        top: 310px;
        max-width: 370px;
        width: 90%;
        @include X_middle();
    }
}

.sns-ad,
.listing-ad {
    @include media-breakpoint-down(sxl) {
        width: 100%;
    }

    &__wrap {
        @include media-breakpoint-down(sxl) {
            width: 100%;
            display: flex;
        }
        &--sp {
            @include media-breakpoint-down(sxl) {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 300px;
                @include media-breakpoint-down(sp) {
                    width: 50%;
                }
            }
        }
    }

    display: flex;
    &__main1,
    &__main2,
    &__main3,
    &__main4 {
        display: flex;
        @include media-breakpoint-down(sxl) {
            flex-direction: column;
            width: 100%;
            padding: 20px;
            margin: 0 !important;
        }
        @include media-breakpoint-down(sp) {
            width: auto;
            padding: 15px;
        }

        &--left {
            width: 112px;
            margin-right: 50px;
            text-align: center;
            @include media-breakpoint-down(sxl) {
                width: auto;
                margin: 0 0 20px 0;
            }
            @include media-breakpoint-down(sp) {
                width: auto;
                margin: 0 0 20px 0;
            }
        }

        &--right {
            @include media-breakpoint-down(sxl) {
                width: auto;
                margin: 0;
                text-align: center;
            }
        }
    }

    &__main2 {
        @include media-breakpoint-down(sxl) {
            padding-top: 35px;
        }
        @include media-breakpoint-down(sp) {
            padding-top: 18px;
        }
        margin-top: 45px;
        &--left {
            margin-bottom: 15px;
        }
        &--right {
            span {
                margin-top: 4px;
                @include media-breakpoint-down(sxl) {
                    margin-top: 0px;
                }
            }
        }
    }
    &__main3 {
        margin-top: 45px;
    }
    &__main4 {
        margin-top: 45px;
        @include media-breakpoint-down(sxl) {
            padding-top: 35px;
        }
        @include media-breakpoint-down(sp) {
            padding-top: 15px;
        }
    }
}
.left1-font,
.left2-font,
.left3-font,
.left4-font {
    white-space: nowrap;
    font-size: 16px;
    font-weight: bold;
    @include media-breakpoint-down(sxl) {
        font-size: 28px;
    }
    @include media-breakpoint-down(sp) {
        font-size: 16px;
    }
}

.right2-font-wrap {
    align-items: flex-start;
}

.right1-font,
.right2-font,
.right3-font,
.right4-font {
    font-size: 20px;
    font-weight: 500;
    margin-top: -8px;
    @include media-breakpoint-down(sxl) {
        font-size: 26px;
        @include media-breakpoint-down(sp) {
            font-size: 15px;
        }
    }

    span {
        font-size: 42px;
        font-weight: bold;
        @include media-breakpoint-down(sp) {
            font-size: 31px;
            margin-right: 3px;
            position: relative;
            top: 2px;
        }
    }
}

.right2-font-small {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    @include media-breakpoint-down(sxl) {
        text-align: left;
        font-size: 19px;
        line-height: 25px;
        display: inline-block;
        margin: 10px auto 0;
    }
    @include media-breakpoint-down(sp) {
        font-size: 12px;
        line-height: 20px;
    }
    @include media-breakpoint-down(ssp) {
        font-size: 11px;
        line-height: 18px;
    }
    
}

.right2-font-wrap,
.right3-font-wrap {
    display: flex;
    @include media-breakpoint-down(sxl) {
        justify-content: center;
    }
}

.section3-img1 {
    height: 110px;
    position: relative;
    &__content {
        position: absolute;
        top: 35px;
        left: calc(50% - 21px);
        @include media-breakpoint-down(sp) {
            display: none;
        }
    }
    @include media-breakpoint-down(sxl) {
        margin-top: 510px;
    }

    &__sp {
        display: none;
        @include media-breakpoint-down(sp) {
            display: block;
            position: absolute;
            top: 780px;
            @include X_middle();
        }
    }
}

.option-wrap {
    position: relative;
    height: 485px;
    @include media-breakpoint-down(sp) {
        height: 380px;
    }
}

.option {
    width: 855px;
    padding: 40px 55px 60px;
    background-color: $color-white;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-down(sxl) {
        width: calc(100% - 50px);
        top: 0;
        padding: 50px 55px 60px;
    }
    @include media-breakpoint-down(sp) {
        top: 35px;
        max-width: 365px;
        width: 90%;
        padding: 15px 25px 15px;
    }

    &__title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        @include media-breakpoint-down(sxl) {
            font-size: 31px;
        }
        @include media-breakpoint-down(sp) {
            font-size: 18px;
        }
        &--border {
            background-color: $color-white-blue;
            height: 3px;
            width: 100%;
            margin-top: 28px;
            @include media-breakpoint-down(sp) {
                margin-top: 12px;
            }
        }
    }

    &__main {
        display: flex;
        @include media-breakpoint-down(sxl) {
            flex-direction: column;
        }

        &--sp1,
        &--sp2 {
            display: flex;
            @include media-breakpoint-down(sxl) {
                display: flex;
                width: 100%;
            }
        }
    }
}

.option1,
.option2,
.option3,
.option4 {
    text-align: center;
    @include media-breakpoint-down(sxl) {
        width: 50%;
    }

    &__title-wrap {
        &--title {
            font-size: 16px;
            font-weight: bold;
            @include media-breakpoint-down(sxl) {
                font-size: 28px;
            }
            @include media-breakpoint-down(sp) {
                font-size: 16px;
            }
        }

        &--detail {
            font-size: 11px;
            font-weight: 500;
            line-height: 15px;
            margin-top: 5px;
            @include media-breakpoint-down(sxl) {
                font-size: 18px;
                font-weight: 500;
                position: relative;
                line-height: 27px;
                top: 15px;
            }
            @include media-breakpoint-down(sp) {
                font-size: 11px;
                line-height: 14px;
                top: 6px;
            }
            @include media-breakpoint-down(ssp) {
                font-size: 10px;
                line-height: 12px;
                top: 6px;
            }
        }
    }

    &__main-top {
        @include media-breakpoint-down(sxl) {
            display: flex;
            margin-top: 35px;
            justify-content: center;
        }
        @include media-breakpoint-down(sxl) {
            margin-top: 28px;
        }
        &--font {
            font-size: 16px;
            font-weight: bold;
            margin-top: 55px;
            @include media-breakpoint-down(sxl) {
                font-size: 28px;
                margin-top: 0;
            }
            @include media-breakpoint-down(sp) {
                font-size: 12px;
                line-height: 14px;
                margin-top: -5px;
            }
        }
        &--text {
            font-size: 20px;
            font-weight: 500;
            margin-top: 15px;
            @include media-breakpoint-down(sxl) {
                font-size: 26px;
                margin-top: -6px;
            }
            @include media-breakpoint-down(sp) {
                font-size: 12px;
                line-height: 14px;
            }
            span {
                font-size: 35px;
                font-weight: bold;
                @include media-breakpoint-down(sxl) {
                    font-size: 42px;
                    margin-left: 15px;
                }
                @include media-breakpoint-down(sp) {
                    font-size: 31px;
                    margin-left: 10px;
                }
            }
        }
        &--wrap {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            p {
                font-size: 20px;
                font-weight: 500;
                margin-top: 15px;
                @include media-breakpoint-down(sxl) {
                    font-size: 26px;
                    margin-top: -6px;
                }
                @include media-breakpoint-down(sp) {
                    font-size: 12px;
                    line-height: 14px;
                }
                span {
                    font-size: 35px;
                    font-weight: bold;
                    @include media-breakpoint-down(sxl) {
                        font-size: 42px;
                        margin-left: 15px;
                    }
                    @include media-breakpoint-down(sp) {
                        font-size: 31px;
                    }
                }
            }
        }
    }

    &__main-bottom {
        @include media-breakpoint-down(sxl) {
            margin-top: 20px;
            display: flex;
            justify-content: center;
        }
        @include media-breakpoint-down(sp) {
            margin-top: 25px;
        }
        &--font {
            font-size: 16px;
            font-weight: bold;
            margin-top: 50px;
            @include media-breakpoint-down(sxl) {
                font-size: 28px;
                justify-content: center;
                margin-top: 0;
            }
            @include media-breakpoint-down(sp) {
                font-size: 12px;
                line-height: 14px;
                margin-top: -5px;
            }
        }
        @include media-breakpoint-down(sxl) {
            display: flex;
        }
        &--text {
            font-size: 20px;
            font-weight: 500;
            margin-top: 18px;
            @include media-breakpoint-down(sxl) {
                font-size: 26px;
                margin-top: -6px;
            }
            @include media-breakpoint-down(sp) {
                font-size: 12px;
                line-height: 14px;
            }
            span {
                font-size: 35px;
                font-weight: bold;
                @include media-breakpoint-down(sxl) {
                    font-size: 42px;
                    margin-left: 15px;
                }
                @include media-breakpoint-down(sp) {
                    font-size: 31px;
                    margin-left: 12px;
                }
            }
        }
    }
}

.option1 {
    width: 170px;
    margin-right: 70px;
    @include media-breakpoint-down(sxl) {
        width: 50%;
    }
    @include media-breakpoint-down(xl) {
        margin-right: 60px;
    }
    @include media-breakpoint-down(sp) {
        margin-right: 23px;
    }
    &__title-wrap {
        margin-top: 40px;
        @include media-breakpoint-down(sp) {
            margin-top: 25px;
        }
    }
    &__main-top {
        @include media-breakpoint-down(sp) {
            margin-top: 28px;
        }
        &--font {
            margin-top: 40px;
            @include media-breakpoint-down(sxl) {
                margin-top: -4px;
            }
        }
    }
    &__main-bottom {
        margin-top: 20px;
    }
}
.option2 {
    &__title-wrap {
        margin-top: 60px;
        @include media-breakpoint-down(sxl) {
            margin-top: 40px;
        }
        @include media-breakpoint-down(sp) {
            margin-top: 25px;
        }
    }
    &__main-bottom {
        &--text {
            span {
                @include media-breakpoint-down(sxl) {
                    margin-left: 18px;
                    margin-right: 10px;
                }
            }
        }
    }
    width: 105px;
    margin-right: 85px;
    @include media-breakpoint-down(sxl) {
        width: 50%;
        margin-right: 0;
    }
}
.option3 {
    &__title-wrap {
        margin-top: 60px;
        @include media-breakpoint-down(sp) {
            margin-top: 30px;
            margin-left: 27px;
        }
    }
    &__main-top {
        @include media-breakpoint-down(sp) {
            margin-left: 10px;
        }
    }
    &__main-bottom {
        @include media-breakpoint-down(sp) {
            margin-left: 8px;
        }
        &--font {
            margin-left: 10px;
            margin-right: 10px;
            @include media-breakpoint-down(sp) {
                margin-left: 0px;
            }
        }
        &--text {
            span {
                @include media-breakpoint-down(sxl) {
                    margin-left: 15px;
                }
                @include media-breakpoint-down(sp) {
                    margin-left: 5px;
                }
            }
        }
    }
    width: 80px;
    margin-right: 85px;
    @include media-breakpoint-down(sxl) {
        width: 50%;
        margin-right: 40px;
    }
}
.option4 {
    width: 150px;
    @include media-breakpoint-down(sxl) {
        width: 50%;
    }
    &__title-wrap {
        margin-top: 60px;
        @include media-breakpoint-down(sp) {
            margin-top: 30px;
        }
    }
    &__main-top {
        @include media-breakpoint-down(sxl) {
            justify-content: center;
            margin-left: -20px;
        }
        @include media-breakpoint-down(sp) {
            margin-left: 3px;
        }
    }
    &__main-bottom {
        @include media-breakpoint-down(sxl) {
            justify-content: center;
            margin-left: -100px;
        }
        @include media-breakpoint-down(sp) {
            margin-left: -60px;
            margin-top: 20px;

            &--text {
                span {
                    margin-left: 16px;
                }
            }
        }
    }
    width: 150px;
    @include media-breakpoint-down(sxl) {
        width: 50%;
    }
    .blue-oval {
        margin-top: 20px;
        @include media-breakpoint-down(sxl) {
            margin-top: 6px;
        }
        @include media-breakpoint-down(sp) {
            margin-top: -5px !important;
        }
    }
}

.section3-img2 {
    margin: 30px 0 90px;
    height: 220px;
    position: relative;
    @include media-breakpoint-down(sxl) {
        width: 100%;
        margin-top: 200px;
    }
    @include media-breakpoint-down(sxl) {
        width: 100%;
        margin-top: 200px;
    }
    @include media-breakpoint-down(sp) {
        margin: 20px auto 165px;
        width: 350px;
        height: auto;
    }
    &__content {
        display: block;
        position: absolute;
        right: calc(50% - 300px);
        @include media-breakpoint-down(sp) {
            width: 350px;
            right: 25px;
        }
        @include media-breakpoint-down(xs) {
            width: 320px;
            right: 25px;
        }
    }
}

.section3-flow-wrap {
    position: relative;
}

.section3-flow {
    position: relative;
    @include X_middle();
    @include media-breakpoint-down(sxl) {
        position: relative;
    }
}

.section3-flow-title {
    margin-bottom: 35px;
    @include media-breakpoint-down(sp) {
        margin-bottom: 15px;
    }
}

.section3-flow-main1,
.section3-flow-main2,
.section3-flow-main3,
.section3-flow-main4 {
    max-width: 860px;
    height: auto;
    background-color: #fff;
    border-radius: 100px;
    position: relative;
    padding-top: 7px;
    margin-bottom: 25px;
    @include media-breakpoint-down(sxl) {
        margin: 7px auto 25px;
        width: calc(100% - 40px);
    }
    @include media-breakpoint-down(sp) {
        max-width: 380px;
        widows: 90%;
        margin-top: -10px;
    }

    &:after {
        content: url("../assets/section3/section3-12.png");
        width: 35px;
        height: auto;
        bottom: -21px;
        left: 170px;
        z-index: 3;
        position: absolute;
        @include media-breakpoint-down(sp) {
            content: url("../assets/section3/section3-16.svg");
            left: 79px;
            bottom: -18px;
        }

        &::after {
            content: url("../assets/section3/section3-11.svg");
            position: absolute;
            width: 35px;
            height: auto;
            bottom: -22px;
            left: 170px;
            @include media-breakpoint-down(sp) {
                content: url("../assets/section3/section3-14.svg");
            }
        }
    }
    &__number {
        position: absolute;
        color: $color-white-blue;
        font-family: $font-roboto;
        font-size: 45px;
        z-index: 3;
        @include media-breakpoint-down(sp) {
            font-size: 34px;
            z-index: 3;
            left: 5px;
            top: 4px;
        }
    }
    &__flex {
        display: flex;
        align-items: center;
        padding: 0 170px 0 130px;
        height: 120px;
        position: relative;
        z-index: 2;
        background-color: #fff;
        width: 860px;
        border-radius: 100px;
        @include media-breakpoint-down(sxl) {
            width: 100%;
            margin: 0 auto;
        }
        @include media-breakpoint-down(xl) {
            padding: 0 60px 0 130px;
        }
        @include media-breakpoint-down(sp) {
            flex-direction: column;
            padding: 0;
            height: 130px;
        }

        &--left {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            position: relative;
            display: inline-block;
            margin-right: 85px;
            line-height: 30px;
            @include media-breakpoint-down(xl) {
                font-size: 18px;
                margin-right: 65px;
            }
            @include media-breakpoint-down(sp) {
                font-size: 17px;
                margin-right: 0;
                margin-top: 2px;
                line-height: 25px;
                margin-right: 0 !important;
            }
        }
        &--right {
            width: 455px;
            font-family: $font-notoSan;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            position: relative;
            text-align: left;
            white-space: initial;
            @include media-breakpoint-down(xl) {
                font-size: 14px;
                line-height: 20px;
            }
            @include media-breakpoint-down(sp) {
                font-size: 15px;
                line-height: 22px;
                margin-top: 15px;
                width: 305px;
                display: block;
                margin: 10px auto 0;
                letter-spacing: 1px;
            }
            @include media-breakpoint-down(ssp) {
                font-size: 13px;
                line-height: 20px;
                margin-top: 15px;
                width: 270px;
                display: block;
                margin: 10px auto 0;
                letter-spacing: 1px;
            }
        }
    }
}

.section3-flow-main1 {
    margin-top: 5px;
    &__background {
        z-index: 4;
    }
}
.section3-flow-main2 {
    &__background {
        z-index: 3;
    }
    &__flex {
        height: 196.85px;
        padding: 0 60px 0 100px;
        @include media-breakpoint-down(sp) {
            padding: 0;
        }
        &--left {
            margin-right: 55px;
            @include media-breakpoint-down(sp) {
                margin-top: 10px;
            }
        }
    }
    &__number {
        @include media-breakpoint-down(sp) {
            left: 5px;
            top: 8px;
        }
    }
}
.section3-flow-main3 {
    &__background {
        z-index: 2;
    }
    &__flex {
        padding-right: 75px;
        @include media-breakpoint-down(sp) {
            height: 169px;
            padding: 0;
        }
        &--left {
            margin-right: 55px;
            @include media-breakpoint-down(sp) {
                margin-top: 10px;
            }
        }
    }
}
.section3-flow-main4 {
    &__flex {
        padding: 0 65px 0 120px;
        @include media-breakpoint-down(sp) {
            padding: 0;
            height: 120px;
        }
        &--left {
            margin-right: 55px;
            @include media-breakpoint-down(sp) {
                margin-right: 0;
                margin-top: 10px;
            }
        }
    }
    &:after {
        display: none;
    }

    .section3-flow-background {
        &:after {
            display: none;
        }
    }
    &__number {
        @include media-breakpoint-down(sp) {
            left: 5px;
            top: 4px;
        }
    }
}

.section3-flow-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 12px;
    border-radius: 100px;
    z-index: 1;
    background-color: #c8eefa;
    left: 0;
    @include media-breakpoint-down(sp) {
        top: 8px;
    }

    &::after {
        content: url("../assets/section3/section3-11.png");
        position: absolute;
        width: 35px;
        height: auto;
        bottom: -22px;
        left: 170px;
        z-index: 3;
        @include media-breakpoint-down(sp) {
            content: url("../assets/section3/section3-14.svg");
            left: 77px;
            bottom: -18px;
        }
    }
}

.tax-caution {
    &::after {
        content: '表示価格は全て税別です';
        position: absolute;
        right: 0;
        bottom: -24px;
        font-weight: 700;

        @include media-breakpoint-down(sp) {
            font-size: 1.2rem;
            bottom: -16px;
        }
    }
}