@mixin response($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
}

@mixin  max-height ($screen) {
    @media (max-height: $screen+'px') {
        @content;
    }
}
@mixin min-height ($screen) {
    @media (min-height: $screen+'px') {
        @content;
    }
}
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    }
    @else {
        @content;
    }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    }
    @else {
        @content;
    }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);
    @if $min !=null and $max !=null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    @else if $max==null {
        @include media-breakpoint-up($lower, $breakpoints) {
            @content;
        }
    }
    @else if $min==null {
        @include media-breakpoint-down($upper, $breakpoints) {
            @content;
        }
    }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $next: breakpoint-next($name, $breakpoints);
    $max: breakpoint-max($next);
    @if $min !=null and $max !=null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    @else if $max==null {
        @include media-breakpoint-up($name, $breakpoints) {
            @content;
        }
    }
    @else if $min==null {
        @include media-breakpoint-down($next, $breakpoints) {
            @content;
        }
    }
}