header {
    width: calc(100% - 352px);
    height: auto;
    padding: {
        right: 40px;
        left: 40px;
    }
    position: fixed;
    background-color: rgba($color: #fff, $alpha: .6);
    top: 0px;
    z-index: 9999;
    
    @include media-breakpoint-down(lg) {
        width: 100%;
    }

    @include media-breakpoint-down(sp) {
        background-color: transparent;
    }

    .header-menu {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(sp) {
            display: none;
        }

        &__left {
            &--font {
                color: $color-black;
                font-family: $font-Yugothic;
                font-weight: bold;
                font-size: 16px;
            }
        }

        &__right {
            width: 550px;
            display: flex;
            justify-content: space-between;
            &--font {
                color: $color-black;
                font-size: 14px;
                font-weight: medium;
            }
        }
    }

    // 特定のセクションが表示領域に入った場合に、ヘッダーナビゲーションに背景が表示される。
    .active {
        position: relative;
        &:after {
            content: url("../assets/header/header04.png");
            position: absolute;
            right: -30px;
            top: -40px;
            z-index: -1;
        }
    }
    .active2 {
        position: relative;
        &:after {
            content: url("../assets/header/header04.png");
            position: absolute;
            right: -36px;
            top: -40px;
            z-index: -1;
        }
    }
    .active3 {
        position: relative;
        &:after {
            content: url("../assets/header/header04.png");
            position: absolute;
            transform: scale(1.2);
            right: -18px;
            top: -40px;
            z-index: -1;
        }
    }
    .active4 {
        position: relative;
        &:after {
            content: url("../assets/header/header04.png");
            position: absolute;
            transform: scale(1.3);
            right: -10px;
            top: -40px;
            z-index: -1;
        }
    }
    .active5 {
        position: relative;
        &:after {
            content: url("../assets/header/header04.png");
            position: absolute;
            transform: scale(1.3);
            width: 170px;
            right: -85px;
            top: -40px;
            z-index: -1;
        }
    }
}

.sp-header {
    display: none;
    @include media-breakpoint-down(sp) {
        display: block;
        position: absolute;
        top: 8px;
        left: 8px;
        font-size: 9px;
        font-family: $font-Yugothic;
        font-weight: bold;
    }
}
