.section2 {
    width: 100%;
    height: auto;
    margin-top: -180px;

    @include media-breakpoint-down(sp) {
        margin-top: 30px;
    }
}

.section2-title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;

    @include media-breakpoint-down(sp) {
        font-size: 2.6rem;
    }

    &__main {
        margin-bottom: 16px;

        @include media-breakpoint-down(sp) {
            margin-bottom: 8px;
        }
    }

    &__sub {
        font-size: 3rem;
        color: $color-dark-blue;

        @include media-breakpoint-down(sp) {
            font-size: 1.6rem;
        }

        span:first-child {
            margin-right: 16px;
        }
    }
}

.slider {
    width: 100%;
}

.slide {
    position: relative;

    .slide-bg {
        z-index: -1;
        position: absolute;
        top: 0;
        right: 50%;
        transform: translateX(50%);
    }
}

.slide-content {
    display: flex;
    max-width: 854px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 32px;
    width: 90%;
    min-height: 440px;

    @include media-breakpoint-down(sp) {
        flex-direction: column;
        width: 80%;
    }

    .thumbnail {
        width: 32%;
        max-width: 266px;
        height: auto;

        @include media-breakpoint-down(sp) {
            width: 100%;
            max-width: unset;
            display: flex;
        }

        .img-wrapper {
            margin-bottom: 16px;
            width: 100%;
            height: auto;

            @include media-breakpoint-down(sp) {
                width: 37%;
                margin-right: 16px;
            }

            img {
                width: 100%;
            }
        }

        &__text {
            font-size: 2rem;
            line-height: 1.5;

            @include media-breakpoint-down(sp) {
                font-size: 1.4rem;
            }
        }
    }

    .slide-main {
        margin-left: auto;
        width: 65%;
        height: auto;

        @include media-breakpoint-down(sp) {
            width: 100%;
        }

        .copy {
            font-size: 2.8rem;
            font-weight: 700;
            line-height: 1.4;
            margin-bottom: 32px;
            text-align: justify;

            @include media-breakpoint-down(sp) {
                font-size: 1.6rem;
                margin-bottom: 16px;
            }
        }

        .detail {
            font-size: 1.6rem;
            line-height: 1.7;
            letter-spacing: 0.001em;
            text-align: justify;

            @include media-breakpoint-down(sp) {
                font-size: 1.2rem;
            }
        }
    }
}

.slider-controller {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: fit-content;

    .arrow-prev,
    .arrow-next {
        width: 48px;
        height: auto;

        @include media-breakpoint-down(sp) {
            width: 36px;
        }

        img {
            width: 100%;
        }
    }

    .arrow-prev img {
        transform: rotate(180deg);
    }
}

.slide-pager {
    display: flex;
    color: transparent;
    margin-right: 80px;
    margin-left: 80px;

    @include media-breakpoint-down(sp) {
        margin-right: 50px;
        margin-left: 50px;
    }

    li {
        margin-right: 30px;

        @include media-breakpoint-down(sp) {
            margin-right: 20px;
        }

        &:last-child {
            margin-right: unset;
        }
    }

    li button {
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 20px;
        text-indent: -9999px;
        -webkit-appearance: none;
        background-color: #E6E6E6;
        padding: 0;

        @include media-breakpoint-down(sp) {
            width: 15px;
            height: 15px;
        }
    }

    .slick-active button {
        transform: scale(1.4);
        background-color: $color-dark-blue;
    }
}

.slick-slide {
    overflow: hidden;
}