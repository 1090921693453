@import "../common";

.fv-main {
    width: 100%;
    height: 535px;
    margin-top: 80px;
    font-family: $font-Yugothic;
    overflow-x: hidden;
    overflow-y: hidden;
    @include media-breakpoint-down(xxl) {
        height: 500px;
    }
    @include media-breakpoint-down(sl) {
        height: 385px;
    }
    @include media-breakpoint-down(sp) {
        margin-top: unset;
        height: 500px;
    }

    &__middle {
        width: 954px;
        margin: 0 auto;
        position: relative;
        @include media-breakpoint-down(xxl) {
            width: 858px;
        }
        @include media-breakpoint-down(sl) {
            width: 650px;
        }
        @include media-breakpoint-down(sp) {
            width: 548px;
            margin-top: 40px;
        }

        &--img {
            width: 97%;
            @include media-breakpoint-down(xxl) {
                width: 858px;
            }
            @include media-breakpoint-down(sl) {
                width: 650px;
            }
        }

        &--font {
            position: absolute;
            font-size: 50px;
            top: 180px;
            @include X_middle();
            white-space: nowrap;
            font-weight: bold;
            line-height: 83px;
            letter-spacing: 2px;
            opacity: 0;
            animation: slideUp 0.6s normal 1.6s forwards;
            @include media-breakpoint-down(xxl) {
                top: 160px;
                font-size: 44px;
            }
            @include media-breakpoint-down(sp) {
                font-size: 33px;
                line-height: 60px;
                top: 155px;
            }
            @include media-breakpoint-down(sl) {
                font-size: 36px;
                line-height: 60px;
                top: 125px;
            }
            @include media-breakpoint-down(sp) {
                top: 160px;
                line-height: 50px;
            }
            @include media-breakpoint-down(xs) {
                top: 160px;
                font-size: 30px;
                letter-spacing: 1px;
            }
        }

        &--emphasis {
            font-size: 60px;
            @include media-breakpoint-down(xxl) {
                font-size: 56px;
            }
            @include media-breakpoint-down(sl) {
                font-size: 42px;
            }
            @include media-breakpoint-down(sp) {
                font-size: 39px;
            }
            @include media-breakpoint-down(xs) {
                font-size: 36px;
            }
        }

        &--sp-group,
        &--sp-top,
        &--sp-bottom,
        &--sp-img {
            display: none;
        }
        .sp-br {
            display: none;
        }
        @include media-breakpoint-down(sp) {
            &--group-wrap,
            &--top-wrap,
            &--bottom-wrap,
            &--img {
                display: none;
            }

            &--sp-group,
            &--sp-top,
            &--sp-bottom,
            &--sp-img {
                display: block;
            }
        }
    }

    &__text {
        font-size: 45px;
        font-weight: bold;
        text-align: center;
        margin-top: 35px;
        letter-spacing: 1px;
        font-family: $font-Yugothic;
        @include media-breakpoint-down(xxl) {
            font-size: 47px;
        }
        @include media-breakpoint-down(sp) {
            margin-top: 95px;
            font-size: 32px;
            line-height: 50px;
            margin-top: 15px;
        }

        &--emphasis {
            font-size: 54px;
            letter-spacing: 4px;
            margin-left: 3px;
            @include media-breakpoint-down(sp) {
                font-size: 39px;
                letter-spacing: 1px;
            }
        }
    }
}

.fv-img1 {
    position: absolute;
    top: 35px;
    left: -155px;
    animation: fadeIn 1s normal none;
    @include media-breakpoint-down(xxl) {
        width: calc(1272px * 0.9);
    }
    @include media-breakpoint-down(sl) {
        width: calc(1272px * 0.7);
        left: -106px;
        top: 25px;
    }
}

.fv-img2 {
    position: absolute;
    bottom: 90px;
    left: -225px;
    z-index: 2;
    animation: fadeIn 1s normal none;
    @include media-breakpoint-down(xxl) {
        width: calc(317px * 0.9);
    }
    @include media-breakpoint-down(sl) {
        width: calc(317px * 0.7);
        bottom: 62px;
        left: -170px;
    }
}
.fv-img3 {
    position: absolute;
    top: -5px;
    left: 325px;
    z-index: 5;
    animation: zoomIn 0.6s normal 1.2s forwards;
    opacity: 0;
    p {
        @include Speech-balloon-font();
        top: -100px;
    }
    @include media-breakpoint-down(xxl) {
        left: 307px;
        img {
            width: calc(305px * 0.9);
        }
        p {
            top: -90px;
        }
    }
    @include media-breakpoint-down(sl) {
        left: 230px;
        img {
            width: calc(305px * 0.7);
        }
        p {
            top: -73px;
            font-size: 14px;
            letter-spacing: -1.2px;
        }
    }
}

.fv-img4 {
    position: absolute;
    top: 15px;
    left: -150px;
    width: 510px;
    animation: zoomIn 0.6s normal 0.8s forwards;
    opacity: 0;
    p {
        @include Speech-balloon-font();
        top: -95px;
    }
    @include media-breakpoint-down(wxl) {
        p {
            right: -40px;
        }
    }
    @include media-breakpoint-down(xxl) {
        left: -125px;
        top: 7px;
        p {
            right: -15px;
            top: -85px;
        }
        img {
            width: calc(510px * 0.9);
        }
    }
    @include media-breakpoint-down(sl) {
        left: -105px;
        top: 4px;

        img {
            width: calc(510px * 0.7);
        }
        p {
            font-size: 14px;
            letter-spacing: -1.2px;
            top: -72px;
            right: 70px;
        }
    }
    @include media-breakpoint-down(ssl) {
        p {
            right: 40px;
        }
    }
    @include media-breakpoint-down(lg) {
        p {
            right: 70px;
        }
    }
    @include media-breakpoint-down(sx) {
        p {
            right: 45px;
        }
    }
}
.fv-img5 {
    position: absolute;
    top: 8px;
    right: 40px;
    z-index: 2;
    animation: zoomIn 0.6s normal 1.6s forwards;
    opacity: 0;
    p {
        @include Speech-balloon-font();
        top: -143px;
        line-height: 24px;
    }
    @include media-breakpoint-down(xxl) {
        right: 15px;
        top: 2px;
        img {
            width: calc(335px * 0.9);
        }
        p {
            top: -125px;
        }
    }
    @include media-breakpoint-down(sl) {
        right: 3px;
        img {
            width: calc(335px * 0.7);
        }
        p {
            top: -103px;
            font-size: 14px;
            letter-spacing: -1.2px;
            line-height: 20px;
        }
    }
}
.fv-img6 {
    position: absolute;
    bottom: 30px;
    right: -105px;
    z-index: 2;
    animation: zoomIn 0.6s normal 0.8s forwards;
    opacity: 0;
    p {
        @include Speech-balloon-font();
        top: -95px;
        height: 0;
    }
    @include media-breakpoint-down(wxl) {
        p {
            left: -15px;
        }
    }
    @include media-breakpoint-down(xxl) {
        right: -80px;
        img {
            width: calc(534px * 0.9);
        }
        p {
            text-align: center;
            top: -85px;
            left: -10px;
        }
    }
    @include media-breakpoint-down(xl) {
        p {
            text-align: center;
            top: -85px;
            left: -40px;
        }
    }
    @include media-breakpoint-down(sl) {
        right: -80px;
        bottom: 25px;
        img {
            width: calc(534px * 0.7);
        }
        p {
            font-size: 14px;
            letter-spacing: -1.2px;
            top: -71px;
            left: 0px;
        }
    }
    @include media-breakpoint-down(ssl) {
        p {
            left: -30px;
        }
    }
    @include media-breakpoint-down(lg) {
        p {
            left: 0px;
        }
    }
    @include media-breakpoint-down(sx) {
        p {
            left: -20px;
        }
    }
}
.fv-img7 {
    position: absolute;
    bottom: -2px;
    left: -35px;
    z-index: 2;
    animation: zoomIn 0.6s normal 1.2s forwards;
    opacity: 0;
    p {
        @include Speech-balloon-font();
        top: -145px;
        line-height: 24px;
        height: 0;
    }
    @include media-breakpoint-down(xxl) {
        left: -18px;
        img {
            width: calc(285px * 0.9);
        }
        p {
            top: -130px;
        }
    }
    @include media-breakpoint-down(sl) {
        left: -18px;
        bottom: -8px;
        img {
            width: calc(285px * 0.7);
        }
        p {
            top: -103px;
            font-size: 14px;
            letter-spacing: -1.2px;
            line-height: 20px;
        }
    }
}
.fv-img8 {
    position: absolute;
    bottom: 5px;
    left: 200px;
    width: 290px;
    z-index: 6;
    animation: zoomIn 0.6s normal 1.6s forwards;
    opacity: 0;
    p {
        @include Speech-balloon-font();
        top: -100px;
        height: 0;
    }
    @include media-breakpoint-down(xxl) {
        img {
            width: calc(290px * 0.9);
        }
        p {
            top: -85px;
            left: -10px;
        }
    }
    @include media-breakpoint-down(sl) {
        left: 155px;
        bottom: -4px;
        img {
            width: calc(290px * 0.7);
        }
        p {
            font-size: 14px;
            letter-spacing: -1.2px;
            top: -67px;
            left: -40px;
        }
    }
}
.fv-img9 {
    position: absolute;
    bottom: -12px;
    left: -160px;
    z-index: 1;
    animation: fadeIn 1s normal none;
    @include media-breakpoint-down(xxl) {
        left: -120px;
        width: calc(1144px * 0.95);
    }
    @include media-breakpoint-down(sl) {
        left: -120px;
        width: calc(1144px * 0.8);
    }
}

.fv-img10 {
    position: absolute;
    right: -250px;
    top: 176px;
    z-index: 1;
    animation: fadeIn 1s normal none;
    @include media-breakpoint-down(xxl) {
        right: -215px;
        img {
            width: calc(317px * 0.9);
        }
    }
    @include media-breakpoint-down(sl) {
        right: -215px;
        top: 125px;
        img {
            width: calc(317px * 0.7);
        }
    }
}

.fv-sp-img1 {
    position: absolute;
    top: -72px;
    right: -115px;
    animation: fadeIn 1s normal none;
}

.fv-sp-img2 {
    position: absolute;
    top: -18px;
    left: 60px;
    opacity: 0;
    animation: zoomIn 0.6s normal 0.8s forwards;
    p {
        top: -90px;
        @include Speech-balloon-sfont();
    }
}

.fv-sp-img3 {
    position: absolute;
    top: -45px;
    right: -2px;
    animation: zoomIn 0.6s normal 1.2s forwards;
    opacity: 0;
    display: block;
    p {
        top: -100px;
        @include Speech-balloon-sfont();
    }
}
.fv-sp-img4 {
    position: absolute;
    top: 33px;
    right: 58px;
    animation: zoomIn 0.6s normal 1.6s forwards;
    opacity: 0;
    p {
        top: -90px;
        @include Speech-balloon-sfont();
    }
}
.fv-sp-img5 {
    position: absolute;
    top: 300px;
    right: 62.5px;
    opacity: 0;
    animation: zoomIn 0.6s normal 0.8s forwards;
    p {
        top: -88px;
        @include Speech-balloon-sfont();
    }
}
.fv-sp-img6 {
    position: absolute;
    top: 345px;
    right: 218px;
    animation: zoomIn 0.6s normal 1.2s forwards;
    opacity: 0;
    p {
        top: -90px;
        @include Speech-balloon-sfont();
    }
}
.fv-sp-img7 {
    position: absolute;
    top: 290px;
    left: 70px;
    animation: zoomIn 0.6s normal 1.6s forwards;
    opacity: 0;
    p {
        top: -65px;
        @include Speech-balloon-sfont();
    }
}
.fv-sp-img8 {
    position: absolute;
    top: -45px;
    right: -2px;
    animation: zoomIn 0.6s normal 1.2s forwards;
    opacity: 0;
    display: block;
    p {
        top: -65px;
        @include Speech-balloon-sfont();
        @include media-breakpoint-down(s) {
            left: -40px;
        }
    }
}

.sp-br {
    display: none;
}

@include media-breakpoint-down(sp) {
    .sp-br {
        display: block;
    }
}
