$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;
//
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: bolder !default;

//フォント
$font-normal: 1.6rem;

// Font family
@font-face {
    font-family: "Myfont";
    src: url('../assets/fonts/Baloo-Regular.ttf');
}

$font-notoSan: "Noto Sans JP", sans-serif;
$font-gothic: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
$font-pierSans: "Montserrat", sans-serif;
$font-Montserrat: "Montserrat", sans-serif;
$font-roboto: "Roboto", sans-serif;
$font-dnp-gothic: "dnp-shuei-mgothic-std", sans-serif;
$font-Baloo2: "Baloo 2", sans-serif;
$font-ryo: "ryo-gothic-plusn", sans-serif;
$font-Baloo: "MyFont", sans-serif;
$font-main: $font-dnp-gothic;
$font-Yugothic: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
$font-Apple: "Apple LiGothic";