.footer {
    position: relative;
    height: 295px;
    overflow: hidden;
    @include media-breakpoint-down(sp) {
        height: 240px;
    }
    &__background {
        position: relative;
        top: -105px;
        left: calc(50% - 700px);
        @include media-breakpoint-down(sp) {
            display: none;
        }
        &--sp{
            display: none;
            @include media-breakpoint-down(sp) {
               display: block;
               top: -20px;
               position: relative;
               left: calc(50% - 340px);
            }
        }
    }
    &__top {
        position: absolute;
        top: 90px;
        @include X_middle();
        @include media-breakpoint-down(sp) {
           top: 80px;
        }
        &--img{
            display: block;
            @include media-breakpoint-down(sp) {
                display: none;
            }
        }
        &--img-sp {
            display: none;
            @include media-breakpoint-down(sp) {
                display: block;
            }
        }
    }
    &__bottom {
        position: absolute;
        top: 195px;
        @include X_middle();
        @include media-breakpoint-down(sp) {
            top: 155px;
        }

        &--font1,
        &--font2 {
            font-size: 16px;
            font-weight: 500px;
            text-align: center;
            white-space: nowrap;
            @include media-breakpoint-down(sp) {
                font-size: 12px;
                line-height: 18px;
            }
        }
        &--font2 {
            margin-top: 12px;
            @include media-breakpoint-down(sp) {
                margin-top: 5px;
            }
        }
        &--font3 {
            text-align: center;
            margin-top: 20px;
            font-size: 10px;
            @include media-breakpoint-down(sp) {
                font-size: 5px;
                margin-top: 12px;
            }
        }
    }
}

.sp-br{
    display: none;
    @include media-breakpoint-down(sp){
        display: block;
    }
}

.ssp-br{
    display: none;
    @include media-breakpoint-down(ssp){
        display: none;
    }
}
