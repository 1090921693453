// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    ssp: 400px,
    xs: 435px,
    xxs: 480px,
    s: 510px,
    ms: 550px,
    ls: 560px,
    sp: 700px,
    sx: 730px,
    md: 860px,
    lg: 1000px,
    ssl: 1060px,
    sl: 1180px,
    xl: 1240px,
    sxl: 1280px,
    xxl: 1310px,
    wwl: 1370px,
    wxl: 1390px,
    txl: 1480px,
    twl: 1500px,
    ttl: 1760px,
) !default;
// scss-docs-end grid-breakpoints

/* >= Tablet min-width: 740px */

/* PC medium resolution > min-width: 1113px */

/* Tablet - PC low resolution (min-width: 740px) and (max-width: 1023px)*/

/* > PC low resolution (min-width: 1024px) and (max-width: 1239px)*/
$top-container: 90%;
$container: 980px;
$side: calc((100vw - #{$container}) / 2);
$main-width: calc(100vw - #{$side});

$height-breakpoints: (
    min: 720px,
) !default;
