.top-view-second {
    top: 1000px;
}

.form {
    height: 100vh;
    width: 352px;
    position: fixed;
    top: 0;
    right: 0;
    font-family: $font-Yugothic;
    background-color: $color-white;
    z-index: 99;
    @include media-breakpoint-down(lg) {
        top: auto;
        bottom: -450px;
        display: block;
        width: 100%;
        height: 450px;
        margin-top: 70px;
        transition: 0.5s;
    }
    @include media-breakpoint-down(sp) {
        height: 650px;
        bottom: -650px;
    }

    &__second {
        position: absolute;
        top: 1000px;
        right: 0;
    }

    &__answer {
        display: flex;
        label {
            display: flex;
            font-size: 13px;
        }
    }

    &__title {
        width: 352px;
        height: 90px;
        background-color: $color-btn;
        color: white;
        border-radius: 15px 0 0 0;
        font-size: 18px;
        @include media-breakpoint-down(lg) {
            height: 70px;
            display: block;
            width: 100%;
            position: relative;
            background-color: transparent;
            top: -70px;
            height: 0px;
        }

        &--bg {
            display: none;
            @include media-breakpoint-down(lg) {
                display: block;
                position: absolute;
                z-index: -1;
                @include X_middle();
                width: 1210px;
            }
        }

        &--font {
            font-size: 18px;
            line-height: 27px;
            font-weight: bold;
            text-align: center;
            position: relative;
            top: 22px;
            @include media-breakpoint-down(lg) {
                font-size: 17px;
                top: 7px;
                position: relative;
                display: inline-block;
                left: 50%;
                transform: translateX(-50%);
            }
            &:before {
                content: url("../assets/header/header01.png");
                position: absolute;
                left: 96px;
                top: 3px;
                @include media-breakpoint-down(lg) {
                    left: -10px;
                }
            }
            &:after {
                content: url("../assets/header/header02.png");
                position: absolute;
                right: 92px;
                top: 3px;
                @include media-breakpoint-down(lg) {
                    right: -10px;
                }
            }
        }

        &--text {
            font-size: 20px;
            line-height: 27px;
            font-weight: bold;
            text-align: center;
            position: relative;
            top: 25px;
            @include media-breakpoint-down(lg) {
                font-size: 20px;
                top: 14px;
                letter-spacing: 0.8px;
            }
        }

        &--lg-img {
            display: none;
            @include media-breakpoint-down(lg) {
                display: block;
                @include X_middle();
                overflow-x: hidden;
                z-index: -1;
            }
            @include media-breakpoint-down(sp) {
                display: none;
            }
        }
        &--sp-img {
            display: none;
            @include media-breakpoint-down(sp) {
                display: block;
                @include X_middle();
                overflow-x: hidden;
                z-index: -1;
            }
        }
    }

    &__main {
        padding: 30px 40px 0;
        @include media-breakpoint-down(sp) {
            padding: 30px 0 0 0;
        }
        &--font {
            font-size: 20px;
            text-align: center;
            margin-top: 105px;
        }
        &--text1 {
            font-size: 16px;
            text-align: center;
            margin-top: 50px;
            font-weight: 500;
        }
        &--text2 {
            font-size: 16px;
            text-align: center;
            margin-top: 15px;
            line-height: 30px;
            font-weight: 500;
        }
    }

    .progressbar {
        position: relative;
        padding: 0 3px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include media-breakpoint-down(lg) {
            padding: 0;
            width: 265px;
            margin: 0 auto;
        }
        li {
            position: relative;
            list-style-type: none;
            text-align: center;
            width: 33.333%;
            color: $color-black;
            font-weight: bold;
            font-size: 17px;
            &:before {
                display: block;
                width: 15px;
                height: 15px;
                margin: 7px auto 19px auto;
                content: "";
                text-align: center;
                border-radius: 50%;
                background: linear-gradient( to right, $color-btn 50%, $no-active 50%);
                background-size: 200% 100%;
                background-position: right bottom;
            }
            &:after {
                position: absolute;
                z-index: -1;
                top: 14px;
                left: -50%;
                width: 100%;
                height: 2px;
                content: "";
                background-color: $no-active;
            }
            &:first-child {
                &:after {
                    content: none;
                }
            }
        }

        .active,
        .complete {
            color: $color-black;
            &:before {
                width: 20px;
                height: 20px;
                margin: 4.5px auto 17px;
                background-position: left bottom;
                transition: 0.8s;
                transition-delay: 0.99s;
            }
            &:after {
                background-color: $no-active;
            }
        }
    }

    .animation-border {
        position: absolute;
        display: block;
        height: 2px;
        left: 35px;
        top: 14px;
        z-index: 1;
        animation: border-back 1s ease 0s forwards;
        background-color: $no-active;
    }

    @keyframes border-back {
        0% {
            width: 0%;
            background-color: $color-btn;
        }

        100% {
            width: 92px;
            background-color: $color-btn;
        }
    }

    .main-form {
        @include media-breakpoint-down(sp) {
            display: block;
            margin: 0 auto;
            width: 320px;
        }
    }

    .attention {
        margin: 30px 0 -8px;
        font-size: 14px;
        font-weight: 500;
        @include media-breakpoint-down(lg) {
            margin: 30px 0 15px;
            text-align: center;
        }
        @include media-breakpoint-down(sp) {
            text-align: left;
            margin-left: 18px;
        }
    }

    .form-require-wrap-top,
    .form-require-wrap-bottom {
        @include media-breakpoint-down(lg) {
            display: flex;
            justify-content: center;
        }
        @include media-breakpoint-down(sp) {
            display: block;
        }
    }

    .form-require-wrap-bottom {
        margin-top: 30px;
        @include media-breakpoint-down(sp) {
            margin-top: 0px;
        }
    }

    .form-require-wrap-bottom-second {
        @include media-breakpoint-down(lg) {
            display: flex;
            justify-content: center;
            margin-top: 0px;
        }
        @include media-breakpoint-down(sp) {
            display: block;
        }
    }

    .form-require {
        margin: 23px 0 0;
        @include media-breakpoint-down(lg) {
            margin: 0 20px;
        }
        @include media-breakpoint-down(sp) {
            margin: 0 20px 22px;
        }
        &__title {
            display: flex;
            &--font {
                font-size: 14px;
                font-weight: bold;
            }
        }

        &__main {
            margin-top: 6px;
            width: 272px;
            height: 32px;
            border: 1px solid $color-textarea;
            font-size: 13px;
            padding: 6px 12px;
            &--answer {
                font-size: 13px;
            }
            &--radio {
                width: 15px;
                height: 15px;
                margin: 0;
            }
        }

        &__select {
            appearance: none;
            margin-top: 8px;
            width: 252px;
            height: 32px;
            border: 1px solid $color-textarea;
            font-size: 13px;
            padding: 6px 12px;
            background-image: url("../assets/contact/contact02.png");
            background-repeat: no-repeat;
            background-position: right 15px center;
        }

        &__textarea {
            width: 272px;
            height: 100px;
            font-family: $font-Yugothic;
            font-size: 13px;
        }

        &__text {
            font-size: 12px;
            color: $color-text;
            margin: 2px 0 4px;
            font-weight: medium;
        }

        &__wrap {
            display: flex;
            margin-top: 15px;
        }

        &__answer {
            margin-right: 50px;
        }
        &__answer2 {
            margin-right: 40px;
        }
    }

    .select-arrow {
        position: relative;
        &:before {
            background-image: url("../assets/contact/contact02.png");
            position: absolute;
            top: 17px;
            right: 35px;
        }
    }

    .form-require2 {
        margin-top: 30px;
        @include media-breakpoint-down(lg) {
            margin-right: 40px;
            margin-left: 0 !important;
        }
        @include media-breakpoint-down(sp) {
            margin-right: 0px;
            margin-left: 18px !important;
            margin-top: 21px;
        }
    }
    .form-require3 {
        margin-top: 30px;
        white-space: nowrap;
        @include media-breakpoint-down(lg) {
            margin-left: 20px;
            margin-right: 0 !important;
        }
        @include media-breakpoint-down(sp) {
            margin-top: 24px;
        }
    }

    .form-check {
        display: flex;
        justify-content: center;
        margin: 25px 0 0 0;

        &__checkbox {
            border-radius: 0;
        }

        &__text {
            font-family: $font-notoSan;
            font-size: 13px;
            font-weight: medium;
        }

        &__img {
            width: 7px;
            height: 7px;
            margin: 1px 2px 1px;
        }
    }

    .form-btn {
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 18px auto 0;
        @include media-breakpoint-down(lg) {
            margin-top: 80px;
        }
    }

    .form-btn1 {
        @include media-breakpoint-down(lg) {
            margin-top: 80px;
        }
    }

    .form-btn2 {
        margin-top: 20px;
    }

    .personal-check {
        display: flex;
        margin-top: 90px;
        font-weight: 500;
        font-family: $font-notoSan;
        @include media-breakpoint-down(lg) {
            margin-top: 50px;
            justify-content: center;
        }
        @include media-breakpoint-down(lg) {
            margin-top: 35px;
        }
    }
}

.no-active {
    display: none !important;
}

.active {
    display: block !important;
}

.lg-br {
    @include media-breakpoint-down(lg) {
        display: none;
    }
    @include media-breakpoint-down(sp) {
        display: block;
    }
}

.first-form {
    @include media-breakpoint-down(lg) {
        margin-top: 0px !important;
    }
    @include media-breakpoint-down(sp) {
        margin-top: 12px !important;
    }
}
.second-form {
    @include media-breakpoint-down(lg) {
        margin-top: 0px !important;
    }
    @include media-breakpoint-down(sp) {
        margin-top: 24px !important;
    }
}

.error-info {
    font-family: $font-Yugothic;
    font-size: 13px;
    font-weight: 500;
    color: #b32929;
    margin-left: 14px;
    @include media-breakpoint-down(lg){
        display: block;
        margin-top: 5px;
    }
}

.personal-check__box {
    display: none;
}

.personal-check__font{
    font-size: 13px;
}

.personal-check input[type="checkbox"]+label {
    display: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 50px;
    padding-right: 10px;
}
/* ラベルの左に表示させる正方形のボックス□ */
.personal-check input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    display: block;
    width: 14px;
    height: 14px;
    margin-top: -6px;
    left: 26px;
    top: 50%;
    border: 1px solid #9D9D9D;
    background-color: #fff; 
}
/* チェックが入った時のレ点 */
.personal-check input[type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 14px;
    height: 7px;
    margin-top: -4px;
    top: 50%;
    left: 26px;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: #585753;
}
